import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  updateProfile,
  UserCredential,
} from "firebase/auth";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  success,
  UnexpectedError,
} from "src/core";
import { auth } from "src/utils/firebase";

export enum AuthenticationType {
  EmailAndPassword = "email_and_password",
}

type EmailAndPasswordSignupParams = {
  type: AuthenticationType.EmailAndPassword;
  email: string;
  password: string;
  name: string;
};

type EmailAndPasswordLoginParams = {
  type: AuthenticationType.EmailAndPassword;
  email: string;
  password: string;
};

export type SignupParams = EmailAndPasswordSignupParams;

export type LoginParams = EmailAndPasswordLoginParams;

const _signupEmailAndPassword = async (
  params: EmailAndPasswordSignupParams
): Promise<FailureOrSuccess<DefaultErrors, UserCredential>> => {
  try {
    const res = await createUserWithEmailAndPassword(
      auth,
      params.email,
      params.password
    );

    // update the user with these new fields if could sign up
    // Note: if they could sign up it means they OWN the account and are now signed in as that account,
    // so we can update it right away
    await updateProfile(res.user, {
      displayName: params.name || res.user.displayName,
    });

    return success(res);
  } catch (err) {
    if (err instanceof FirebaseError) {
      if (err.code === "auth/email-already-in-use") {
        return failure(
          new Error("This email is already used by another account.")
        );
      }

      if (err.code === "auth/weak-password") {
        return failure(
          new Error(
            "Your password is too short, please make it longer than 6 characters."
          )
        );
      }
    }

    return failure(new UnexpectedError(err));
  }
};

const _loginEmailAndPassword = async (
  params: EmailAndPasswordLoginParams
): Promise<FailureOrSuccess<DefaultErrors | FirebaseError, UserCredential>> => {
  try {
    const res = await signInWithEmailAndPassword(
      auth,
      params.email,
      params.password
    );

    return success(res);
  } catch (err) {
    if (err instanceof FirebaseError) {
      if (err.code === "auth/wrong-password") {
        const methods = await fetchSignInMethodsForEmail(auth, params.email);

        // check to see if google is supported. it is possible that they have the wrong password because they actually have an account with google
        // instead of email/password
        if (methods.indexOf(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD) != -1) {
          return failure(
            new Error(
              "Please login with the Google account associated with this account. "
            )
          );
        }
        return failure(new Error("Invalid password."));
      }

      if (err.code === "auth/user-not-found") {
        return failure(new Error("There is no user with this email."));
      }

      if (err.code === "auth/user-not-found") {
        return failure(new Error("There is no user with this email."));
      }

      if (err.code === "auth/multi-factor-auth-required") {
        return failure(err);
      }

      if (err.code === "auth/account-exists-with-different-credential") {
        const methods = await fetchSignInMethodsForEmail(auth, params.email);

        if (
          methods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1
        ) {
          return failure(
            new Error(
              "Please login with the email and password associated with this account. "
            )
          );
        }

        if (methods.indexOf(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD) != -1) {
          return failure(
            new Error(
              "Please login with the Google account associated with this account. "
            )
          );
        }

        return failure(
          new Error("This email is associated with a different login method.")
        );
      }

      return failure(err);
    }

    return failure(new UnexpectedError("This user already has an account."));
  }
};

/**
 * @deprecated don't do signup on the frontend
 *
 * @param params
 * @returns
 */
export const signup = async (
  params: SignupParams
): Promise<FailureOrSuccess<DefaultErrors, UserCredential>> => {
  switch (params.type) {
    case AuthenticationType.EmailAndPassword:
      return _signupEmailAndPassword(params);
    default:
      return failure(new UnexpectedError("This signup type is not supported"));
  }
};

export const login = async (
  params: LoginParams
): Promise<FailureOrSuccess<DefaultErrors | FirebaseError, UserCredential>> => {
  switch (params.type) {
    case AuthenticationType.EmailAndPassword:
      return _loginEmailAndPassword(params);
    default:
      return failure(new UnexpectedError("This signup type is not supported"));
  }
};

export const AuthenticationService = {
  /**
   * @deprecated
   */
  signup,
  login,
};
