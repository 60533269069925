import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import {
  Link,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationCreateUserArgs,
  MutationStartCallArgs,
  PhoneNumber,
  Query,
  QueryGetDailyPromptsArgs,
  QueryGetFamilyMemberArgs,
  QueryGetFamilyMemberMetricsArgs,
} from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import { BaseDailyPromptFields, BaseUserFields } from "src/api/fragments";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { DailyPromptModal } from "src/components/modals";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { Touchable } from "src/components/Touchable";
import { FamilyMemberDescriptionModal } from "src/components/modals/FamilyMemberDescriptionModal";
import moment from "moment-timezone";
import { FamilyMemberModal } from "src/components/modals/FamilyMemberModal";
import { DailyPromptRow } from "./DailyPromptRow";
import { FamilyMemberActiveTab, FamilyMemberContext } from "./context";
import { ScheduleModal } from "src/components/modals/ScheduleModal";
import { getTZAbb } from "src/utils/helpers";
import { FamilyMemberSchedule } from "./Schedule";
import Details from "./Details";
import Memories from "./Memories";
import Conversations from "./Conversations";
import { ShareRuthContactModal } from "src/components/modals/ShareRuthContactModal";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

export function FamilyMember() {
  const [search] = useSearchParams();
  const toast = useMyToast();
  const navigate = useNavigate();
  const { familyMemberId, familySlug } = useParams<{
    familyMemberId: string;
    familySlug: string;
  }>();

  // path with react native dom
  const _path = window.location.pathname.split("/").pop() || "details";
  const lastPath = _path === familyMemberId ? "details" : _path;

  // last path name param
  const theme = useTheme();

  const [activeTab, setActiveTab] = React.useState<FamilyMemberActiveTab>(
    lastPath as FamilyMemberActiveTab
  );

  console.log(activeTab);

  useEffect(() => setActiveTab(lastPath as FamilyMemberActiveTab), [lastPath]);

  const dailyPromptVariables = useMemo(
    (): QueryGetDailyPromptsArgs => ({
      familyMemberId: familyMemberId || "",
    }),
    []
  );

  const familyMemberVariables = useMemo(
    (): QueryGetFamilyMemberArgs => ({
      familyMemberId: familyMemberId || "",
    }),
    []
  );

  const { data: familyMemberData } = useQuery<Pick<Query, "getFamilyMember">>(
    api.families.getMember,
    {
      variables: familyMemberVariables,
    }
  );

  const { data: dailyPromptsData } = useQuery<Pick<Query, "getDailyPrompts">>(
    api.dailyPrompts.forFamilyMember,
    {
      variables: dailyPromptVariables,
    }
  );

  const { data: conversationsData } = useQuery<Pick<Query, "getConversations">>(
    api.conversations.forMember,
    {
      variables: dailyPromptVariables,
    }
  );

  const dailyPrompts = useMemo(() => {
    return dailyPromptsData?.getDailyPrompts || [];
  }, [dailyPromptsData]);

  const conversations = useMemo(() => {
    return conversationsData?.getConversations || [];
  }, [conversationsData]);

  useEffect(() => {
    // scroll to the top
    window.scrollTo(0, 0);
  }, []);

  const familyMember = familyMemberData?.getFamilyMember;
  const isLarge = useIsLargeScreen();

  return (
    <FamilyMemberContext.Provider
      value={{
        familyMember: familyMember ?? null,
        dailyPrompts,
        conversations,
        activeTab,
        setActiveTab,
      }}
    >
      <div style={{ height: "100%" }}>
        <DailyPromptModal />
        <FamilyMemberDescriptionModal />
        <FamilyMemberModal />
        <ShareRuthContactModal />

        <VStack
          padding="2rem 0"
          flexDir="column"
          display="flex"
          justifyContent="center"
          style={{
            maxWidth: "50rem",
            margin: "auto",
          }}
        >
          <div style={{ width: "100%" }}>
            <HStack
              style={{
                marginBottom: 20,
              }}
            >
              {/* <Touchable
                style={{
                  alignSelf: "flex-start",

                  display: "inline-block",
                }}
                onClick={() => navigate(-1)}
              >
                <i
                  className="fas fa-chevron-left"
                  style={{
                    color: theme.text,
                    fontSize: 16,
                  }}
                />
                Back
              </Touchable> */}

              <Flex
                flex={1}
                style={{
                  padding: 10,
                  justifyContent: "flex-start",
                }}
              >
                <Touchable
                  onClick={() =>
                    navigate(
                      `/app/${familySlug}/members/${familyMemberId}/details`
                    )
                  }
                  style={{
                    marginRight: 10,
                    color: activeTab === "details" ? colors.white : theme.text,
                    backgroundColor:
                      activeTab === "details" ? colors.primary : "transparent",
                  }}
                >
                  Details <i className="fas fa-user-circle" />
                </Touchable>

                <Touchable
                  onClick={() =>
                    navigate(
                      `/app/${familySlug}/members/${familyMemberId}/memories`
                    )
                  }
                  style={{
                    marginRight: 10,
                    color: activeTab === "memories" ? colors.white : theme.text,
                    backgroundColor:
                      activeTab === "memories" ? colors.primary : "transparent",
                  }}
                >
                  Stories <i className="fas fa-scroll" />
                </Touchable>

                <Touchable
                  onClick={() =>
                    navigate(
                      `/app/${familySlug}/members/${familyMemberId}/conversations`
                    )
                  }
                  style={{
                    color:
                      activeTab === "conversations" ? colors.white : theme.text,
                    backgroundColor:
                      activeTab === "conversations"
                        ? colors.primary
                        : "transparent",
                  }}
                >
                  Conversations <i className="fas fa-comments" />
                </Touchable>
              </Flex>
            </HStack>

            <Box
              display="flex"
              flexDir="column"
              margin="auto"
              marginTop="1rem"
              padding={isLarge ? "2rem" : "1rem"}
              w="100%"
              borderRadius={other.borderRadius}
              bg={theme.background}
              border={`1px solid ${theme.border}`}
            >
              {(!activeTab || activeTab === "details") && <Details />}
              {activeTab === "memories" && <Memories />}
              {activeTab === "conversations" && <Conversations />}
            </Box>
          </div>
        </VStack>
      </div>
    </FamilyMemberContext.Provider>
  );
}

const Sections = () => {
  const toast = useMyToast();

  return (
    <HStack
      style={{
        margin: "25px 0",
      }}
    >
      <SingleSection
        label="Memories"
        description="Record all the stories and memories of your loved ones"
        color="#5DCCA0"
        // stories icon
        iconName="fas fa-scroll"
      />
      <SingleSection
        label="Learn"
        description="Keep your brain sharp by learning new things every day"
        color="#cb9bfa"
        // stories icon
        iconName="fas fa-brain"
        isAvailable={false}
        onClick={() => toast.show({ status: "info", message: "Coming soon" })}
      />
      <SingleSection
        label="Games"
        isAvailable={false}
        description="Games to help strengthen your memory."
        color="#f8fd91"
        // stories icon
        iconName="fas fa-gamepad"
        onClick={() => toast.show({ status: "info", message: "Coming soon" })}
      />
    </HStack>
  );
};

const SingleSection = ({
  color,
  label,
  description,
  iconName,
  isAvailable = true,
  onClick,
}: {
  color: string;
  label: string;
  description: string;
  iconName: string;
  isAvailable?: boolean;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        background: color,
        borderRadius: 15,
        padding: 10,
        flex: 1,
        cursor: "pointer",
        opacity: isAvailable ? 1 : 0.4,
      }}
      onClick={onClick}
    >
      <HStack
        style={{
          color: colors.gray05,
        }}
      >
        <Text
          style={{
            flex: 1,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {label}
        </Text>
        <i className={iconName} />
      </HStack>
      <Text
        style={{
          color: colors.black,
          fontSize: 14,
          marginTop: 10,
        }}
      >
        {description}
      </Text>
    </Box>
  );
};
