import {
  Button,
  Container,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { Modal } from "../Modal";
import { BaseDailyPromptFields } from "src/api/fragments";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddDailyPromptArgs,
  MutationUpdateDailyPromptArgs,
  Query,
} from "src/api/generated/types";
import { Input, Textarea } from "../Form";
import { useMyToast } from "src/hooks";

type Props = InjectedProps & {
  familyMemberId: string;
  dailyPrompt?: BaseDailyPromptFields;
};

function _DailyPromptModal({
  handleHide,
  show: isVisible,
  familyMemberId,
  dailyPrompt,
}: Props) {
  const theme = useTheme();
  const toast = useMyToast();

  const [question, setQuestion] = useState<string | null>(
    dailyPrompt?.prompt || ""
  );

  const [addDailyPrompt] = useMutation<
    Pick<Mutation, "addDailyPrompt">,
    MutationAddDailyPromptArgs
  >(api.dailyPrompts.add);

  const [updateDailyPrompt] = useMutation<
    Pick<Mutation, "updateDailyPrompt">,
    MutationUpdateDailyPromptArgs
  >(api.dailyPrompts.update);

  const _addQuestion = async () => {
    if (!familyMemberId) {
      return toast.show({
        message: "No family member found.",
        status: "error",
      });
    }

    if (!question) {
      return toast.show({
        message: "Please enter a question.",
        status: "error",
      });
    }

    const variables: MutationAddDailyPromptArgs = {
      question: question,
      familyMemberId: familyMemberId,
    };

    const response = await addDailyPrompt({
      variables,
      refetchQueries: [api.dailyPrompts.forFamilyMember],
    });

    toast.show({
      message: "Question added.",
      status: "success",
    });

    handleHide();
  };

  const _updateQuestion = async () => {
    if (!dailyPrompt) {
      return toast.show({
        message: "No daily prompt found.",
        status: "error",
      });
    }

    if (!question) {
      return toast.show({
        message: "Please enter a question.",
        status: "error",
      });
    }

    const variables: MutationUpdateDailyPromptArgs = {
      question: question,
      dailyPromptId: dailyPrompt?.id || "",
    };

    const response = await updateDailyPrompt({
      variables,
      refetchQueries: [api.dailyPrompts.forFamilyMember],
    });

    toast.show({
      message: "Question updated.",
      status: "success",
    });

    handleHide();
  };

  const _onSaveQuestion = async () => {
    try {
      if (dailyPrompt) {
        await _updateQuestion();
      } else {
        await _addQuestion();
      }
    } catch (err) {
      toast.show({
        message: "An error occurred while saving the question.",
        status: "error",
      });
    }

    // TODO:
  };

  return (
    <Modal
      title={`Question`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={_onSaveQuestion}
        >
          Save Question
        </Button>
      }
    >
      <Textarea
        label="Question"
        autoFocus={true}
        value={question || ""}
        onChange={(e) => setQuestion(e.target.value)}
      />

      <br />
    </Modal>
  );
}

export const DailyPromptModal = connectModal({
  name: "DailyPromptModal",
})(_DailyPromptModal);
