export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: Date;
};

export enum CallFrequencyEnum {
  Daily = 'Daily',
  Hourly = 'Hourly',
  Weekly = 'Weekly'
}

export type Conversation = {
  __typename?: 'Conversation';
  callSummary?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  durationFormatted?: Maybe<Scalars['String']>;
  durationMs?: Maybe<Scalars['Int']>;
  familyMemberId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  rawText?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  retellCallId?: Maybe<Scalars['String']>;
  status: ConversationStatusEnum;
  transcript: Array<TranscriptMessage>;
  transcriptionUrl?: Maybe<Scalars['String']>;
};

export enum ConversationStatusEnum {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Missed = 'Missed',
  None = 'None',
  Started = 'Started'
}

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  token: Scalars['String'];
  user: User;
};

export type DailyPrompt = {
  __typename?: 'DailyPrompt';
  conversations?: Maybe<Array<Conversation>>;
  createdAt: Scalars['Date'];
  familyMemberId: Scalars['String'];
  id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  position: Scalars['Int'];
  prompt: Scalars['String'];
};

export type Family = {
  __typename?: 'Family';
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type FamilyMember = {
  __typename?: 'FamilyMember';
  daysOfWeek?: Maybe<Array<Scalars['Int']>>;
  description?: Maybe<Scalars['String']>;
  familyId: Scalars['ID'];
  formattedFromPhoneNumber?: Maybe<Scalars['String']>;
  formattedPhone?: Maybe<Scalars['String']>;
  fromPhoneNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interestDescription?: Maybe<Scalars['String']>;
  interests: Array<Interest>;
  isElder: Scalars['Boolean'];
  lastCallAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  nextCallAt?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
  phoneNumberId?: Maybe<Scalars['ID']>;
  shouldAutomaticallyCall?: Maybe<Scalars['Boolean']>;
  timesOfDay?: Maybe<Array<Scalars['String']>>;
  timezone: Scalars['String'];
  timezoneOffset: Scalars['Int'];
  userId?: Maybe<Scalars['ID']>;
};

export type FamilyMemberMetrics = {
  __typename?: 'FamilyMemberMetrics';
  callsThisWeek: Array<Conversation>;
  completedCallsTotal: Scalars['Int'];
  missedCallsThisWeek: Array<Conversation>;
  missedCallsTotal: Scalars['Int'];
};

export type Interest = {
  __typename?: 'Interest';
  category: Scalars['String'];
  description: Scalars['String'];
};

export type InterestInput = {
  category: Scalars['String'];
  description: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDailyPrompt: DailyPrompt;
  addFamilyMember: FamilyMember;
  createFamily: Family;
  createUser: CreateUserResponse;
  orderDailyPrompts: Array<DailyPrompt>;
  removeDailyPrompt: DailyPrompt;
  setActiveFamily: Family;
  setSchedule: FamilyMember;
  startCall?: Maybe<Conversation>;
  testCall: Conversation;
  updateActiveFamilyMember: FamilyMember;
  updateDailyPrompt: DailyPrompt;
  updateFamilyMember: FamilyMember;
  updateMe: User;
};


export type MutationAddDailyPromptArgs = {
  familyMemberId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationAddFamilyMemberArgs = {
  description?: InputMaybe<Scalars['String']>;
  familyId: Scalars['ID'];
  interestDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateFamilyArgs = {
  name: Scalars['String'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  isElder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationOrderDailyPromptsArgs = {
  dailyPromptIds: Array<Scalars['ID']>;
};


export type MutationRemoveDailyPromptArgs = {
  dailyPromptId: Scalars['ID'];
};


export type MutationSetActiveFamilyArgs = {
  familyId: Scalars['ID'];
};


export type MutationSetScheduleArgs = {
  daysOfWeek: Array<Scalars['Int']>;
  familyMemberId: Scalars['ID'];
  shouldAutomaticallyCall: Scalars['Boolean'];
  timesOfDay: Array<Scalars['String']>;
  timezone: Scalars['String'];
};


export type MutationStartCallArgs = {
  dailyPromptId?: InputMaybe<Scalars['ID']>;
  familyMemberId: Scalars['ID'];
};


export type MutationTestCallArgs = {
  familyMemberId: Scalars['ID'];
};


export type MutationUpdateActiveFamilyMemberArgs = {
  biography?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Scalars['String']>;
  isElder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDailyPromptArgs = {
  dailyPromptId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationUpdateFamilyMemberArgs = {
  description?: InputMaybe<Scalars['String']>;
  familyMemberId: Scalars['ID'];
  interestDescription?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Array<InterestInput>>;
  name?: InputMaybe<Scalars['String']>;
  nextCallAt?: InputMaybe<Scalars['Date']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneNumberId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateMeArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  createdAt: Scalars['Date'];
  familyId: Scalars['String'];
  id: Scalars['String'];
  isVerified: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type PotentialQuestion = {
  __typename?: 'PotentialQuestion';
  id?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getActiveFamily: Family;
  getActiveFamilyMember: FamilyMember;
  getConversation: Conversation;
  getConversations: Array<Conversation>;
  getDailyPrompt: DailyPrompt;
  getDailyPrompts: Array<DailyPrompt>;
  getFamilies: Array<Family>;
  getFamily?: Maybe<Family>;
  getFamilyBySlug: Family;
  getFamilyMember: FamilyMember;
  getFamilyMemberBySlug: FamilyMember;
  getFamilyMemberMetrics: FamilyMemberMetrics;
  getFamilyMembers: Array<FamilyMember>;
  getFamilyPhones: Array<PhoneNumber>;
  getPhoneNumber: PhoneNumber;
  getPotentialQuestions: Array<PotentialQuestion>;
  me?: Maybe<User>;
};


export type QueryGetActiveFamilyArgs = {
  overrideFamilyId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetActiveFamilyMemberArgs = {
  overrideFamilyId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetConversationArgs = {
  conversationId: Scalars['ID'];
};


export type QueryGetConversationsArgs = {
  familyMemberId: Scalars['ID'];
};


export type QueryGetDailyPromptArgs = {
  dailyPromptId: Scalars['ID'];
};


export type QueryGetDailyPromptsArgs = {
  familyMemberId: Scalars['ID'];
};


export type QueryGetFamilyBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetFamilyMemberArgs = {
  familyMemberId: Scalars['ID'];
};


export type QueryGetFamilyMemberBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetFamilyMemberMetricsArgs = {
  familyMemberId: Scalars['ID'];
};


export type QueryGetFamilyMembersArgs = {
  familyId: Scalars['ID'];
  onlyElders?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetFamilyPhonesArgs = {
  familyId: Scalars['ID'];
};


export type QueryGetPhoneNumberArgs = {
  phoneNumberId: Scalars['ID'];
};


export type QueryGetPotentialQuestionsArgs = {
  familyMemberId: Scalars['ID'];
};

export type TranscriptMessage = {
  __typename?: 'TranscriptMessage';
  content: Scalars['String'];
  role: Scalars['String'];
  words: Array<TranscriptMessageWord>;
};

export type TranscriptMessageWord = {
  __typename?: 'TranscriptMessageWord';
  end?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  word?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  activeFamilyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['String'];
  isSuperuser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export enum UserTypeEnum {
  Pending = 'Pending',
  Verified = 'Verified'
}
