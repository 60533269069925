import { Box, HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { BaseFamilyMemberFields } from "src/api/fragments";
import { ScheduleModal } from "src/components/modals/ScheduleModal";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { getTZAbb } from "src/utils/helpers";

export const FamilyMemberSchedule = ({
  familyMember,
}: {
  familyMember: BaseFamilyMemberFields | null;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const timezone = familyMember?.timezone || "UTC";

  const daysOfWeek = useMemo(
    () => familyMember?.daysOfWeek || [],
    [familyMember]
  );

  const timesOfDay = useMemo(
    () => familyMember?.timesOfDay || [],
    [familyMember]
  );

  const formattedTimeOfDay = useMemo(() => {
    return timesOfDay.map((time) => {
      // time is something like "16:14" or "6:15". make it into a date and render as h:mma
      return moment(time, "HH:mm").format("h:mma");
    });
  }, [timesOfDay]);

  const activeIndexes = useMemo(() => {
    if (!daysOfWeek || !daysOfWeek.length) return new Set();
    return new Set(daysOfWeek);
  }, [daysOfWeek]);

  // all days of the week
  const allDaysOfWeek = [
    { name: "Monday", index: 0 },
    { name: "Tuesday", index: 1 },
    { name: "Wednesday", index: 2 },
    { name: "Thursday", index: 3 },
    { name: "Friday", index: 4 },
    { name: "Saturday", index: 5 },
    { name: "Sunday", index: 6 },
  ];

  const shouldAutomaticallyCall = familyMember?.shouldAutomaticallyCall;
  const timezoneAbbr = getTZAbb(familyMember?.timezone || "UTC");

  if (!familyMember) {
    return null;
  }

  return (
    <div style={{ marginTop: 15, marginBottom: 25, width: "100%" }}>
      <ScheduleModal />

      <HStack style={{ marginBottom: 25 }}>
        <Box flex={1}>
          <Text
            style={{
              fontSize: 16,
              color: theme.header,
              fontWeight: "bold",
            }}
          >
            Call Schedule
          </Text>

          <Text
            style={{
              marginTop: 5,
              fontSize: 16,
              color: theme.text,
            }}
          >
            Selected timezone is{" "}
            <span style={{ fontWeight: "bold", color: colors.primary }}>
              {timezoneAbbr}
            </span>
          </Text>
        </Box>

        <Touchable
          onClick={() =>
            dispatch(
              show("ScheduleModal", {
                familyMember,
              })
            )
          }
          style={{ marginLeft: 10, color: theme.text }}
        >
          Edit Schedule <i className="fas fa-pen" />
        </Touchable>
      </HStack>

      <HStack
        style={{
          marginTop: 10,
          // space in between each of the flex items
          gap: 5,
          width: "100%",
          // wrap to next line if too wide but make all same size
          flexWrap: "wrap",
        }}
      >
        {allDaysOfWeek.map((day) => {
          const isActive = activeIndexes.has(day.index);

          return (
            <div
              key={day.index}
              style={{
                padding: 10,
                cursor: "pointer",
                opacity: shouldAutomaticallyCall === false ? 0.3 : 1,
                backgroundColor: theme.secondaryBackground,
                borderRadius: 15,
                flex: 1,
                textAlign: "center",
                fontSize: 14,
              }}
            >
              <Text
                style={{
                  color: theme.text,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {isActive ? formattedTimeOfDay.join(", ") : "–"}
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  marginTop: 0,
                  color: theme.text,
                }}
              >
                {abbrWeekDay((day.index + 1) % 7)}
              </Text>
            </div>
          );
        })}
      </HStack>
    </div>
  );
};

const abbrWeekDay = (day: number): string => {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day];
};
