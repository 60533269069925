import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import { BaseDailyPromptFields, BaseUserFields } from "src/api/fragments";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { DailyPromptModal } from "src/components/modals";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { Touchable } from "src/components/Touchable";
import { FamilyMemberDescriptionModal } from "src/components/modals/FamilyMemberDescriptionModal";
import moment from "moment-timezone";
import { FamilyMemberModal } from "src/components/modals/FamilyMemberModal";
import { DailyPromptRow } from "./DailyPromptRow";
import { FamilyMemberContext } from "./context";
import { ScheduleModal } from "src/components/modals/ScheduleModal";
import { getTZAbb } from "src/utils/helpers";
import { FamilyMemberSchedule } from "./Schedule";

function Memories() {
  const [activeTab, setActiveTab] = React.useState<"upcoming" | "completed">(
    "upcoming"
  );
  const { dailyPrompts: _dailyPrompts } = useContext(FamilyMemberContext);

  const dailyPrompts = useMemo(() => {
    const prompts = _dailyPrompts.filter((p) => {
      if (activeTab === "upcoming") {
        return !p.isCompleted;
      }
      return p.isCompleted;
    });

    // sort position descending
    prompts.sort((a, b) => {
      return (a.position ?? 0) - (b.position ?? 0);
    });

    return prompts;
  }, [_dailyPrompts, activeTab]);

  const numCompleted = useMemo(
    () => _dailyPrompts.filter((p) => p.isCompleted).length,
    [_dailyPrompts]
  );

  return (
    <div>
      <Tabs
        numCompleted={numCompleted ?? 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div style={{ marginTop: 25 }}>
        {dailyPrompts.map((dailyPrompt: BaseDailyPromptFields) => (
          <DailyPromptRow dailyPrompt={dailyPrompt} />
        ))}
      </div>
    </div>
  );
}

const Tabs = ({
  activeTab,
  setActiveTab,
  numCompleted,
}: {
  activeTab: "upcoming" | "completed";
  setActiveTab: (tab: "upcoming" | "completed") => void;
  numCompleted: number;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id: familyMemberId } = useParams<{ id: string }>();

  return (
    <HStack>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Touchable
          onClick={() => {
            setActiveTab("upcoming");
          }}
          style={{
            marginRight: 10,
            background:
              activeTab === "upcoming" ? colors.primary : "transparent",
            color: activeTab === "upcoming" ? colors.white : theme.text,
          }}
        >
          <Text
            style={{
              fontWeight: 700,
            }}
          >
            Upcoming
          </Text>
        </Touchable>

        <Touchable
          onClick={() => {
            setActiveTab("completed");
          }}
          style={{
            background:
              activeTab === "completed" ? colors.primary : "transparent",
            color: activeTab === "completed" ? colors.white : theme.text,
          }}
        >
          <Text
            style={{
              fontWeight: 700,
            }}
          >
            Completed {numCompleted ? `(${numCompleted})` : ""}
          </Text>
        </Touchable>
      </div>

      <Button
        style={{
          background: colors.primary,
          color: colors.white,
        }}
        onClick={() =>
          dispatch(
            show("DailyPromptModal", {
              familyMemberId: familyMemberId,
            })
          )
        }
      >
        Add Question
      </Button>
    </HStack>
  );
};

export default Memories;
