import { initializeApp } from "firebase/app";
import { config } from "../config";
import { getAuth } from "firebase/auth";

export const app = initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId,
});

export const auth = getAuth(app);

export async function getAuthToken(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      currentUser.getIdToken().then(resolve).catch(reject);
    } else {
      return resolve(null);
    }
  });
}
