import {
  Button,
  Container,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { Modal } from "../Modal";
import {
  BaseDailyPromptFields,
  BaseFamilyMemberFields,
} from "src/api/fragments";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddDailyPromptArgs,
  MutationUpdateDailyPromptArgs,
  MutationUpdateFamilyMemberArgs,
  Query,
} from "src/api/generated/types";
import { Input, Textarea } from "../Form";
import { useMyToast } from "src/hooks";

type Props = InjectedProps & {
  familyMember?: BaseFamilyMemberFields;
};

function _FamilyMemberModal({
  handleHide,
  show: isVisible,
  familyMember,
}: Props) {
  const theme = useTheme();
  const toast = useMyToast();

  const [phone, setPhone] = useState<string | null>(familyMember?.phone || "");
  const [name, setName] = useState<string | null>(familyMember?.name || "");

  const [updateFamilyMember] = useMutation<
    Pick<Mutation, "updateFamilyMember">,
    MutationUpdateFamilyMemberArgs
  >(api.families.updateMember);

  const _updateFamilyMember = async () => {
    if (!familyMember) {
      return toast.show({
        message: "No family member found.",
        status: "error",
      });
    }

    if (!phone) {
      return toast.show({
        message: "Please update the phone.",
        status: "error",
      });
    }

    const variables: MutationUpdateFamilyMemberArgs = {
      familyMemberId: familyMember.id,
      phone: phone,
      name: name,
    };

    const response = await updateFamilyMember({
      variables,
      refetchQueries: [api.families.getMember, api.families.members],
    });

    toast.show({
      message: "Updated family member.",
      status: "success",
    });

    handleHide();
  };

  const _onSave = async () => {
    try {
      if (familyMember) {
        await _updateFamilyMember();
      }
    } catch (err) {
      toast.show({
        message: "An error occurred while updating family member.",
        status: "error",
      });
    }

    // TODO:
  };

  return (
    <Modal
      title={`${familyMember?.name || "Family Member"}`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={_onSave}
        >
          Save
        </Button>
      }
    >
      {/* name */}
      <Input
        label="Name"
        autoFocus={true}
        value={name || ""}
        onChange={(e) => setName(e.target.value)}
      />

      <Input
        label="Phone Number"
        // autoFocus={true}
        value={phone || ""}
        onChange={(e) => setPhone(e.target.value)}
      />

      <br />
    </Modal>
  );
}

export const FamilyMemberModal = connectModal({
  name: "FamilyMemberModal",
})(_FamilyMemberModal);
