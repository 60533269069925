import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Outlet,
  useNavigate,
  Navigate,
  useMatch,
  useParams,
} from "react-router-dom";
import { useAuth } from "./useAuth";

import { Login, Signup } from "src/views/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuthStatus, getUserIsLoggedIn } from "src/redux/reducers/user";
import { signOut } from "firebase/auth";
import { auth } from "src/utils/firebase";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { AuthStatus } from "src/redux/types";
import { Box, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { setTheme } from "src/redux/reducers/globalState";
import { useTheme } from "src/hooks/useTheme";
import { Dashboard } from "src/views/Main/Dashboard";
import { UpsertFamilyMember } from "src/views/Main/UpsertFamilyMember";
import { FamilyMember } from "src/views/Main/FamilyMember";
import { DailyPrompt } from "src/views/Main/DailyPrompt";

import Header from "src/components/Header";
import { OnboardFamilyMember } from "src/views/Main/OnboardFamilyMember.ts";
import { Welcome } from "src/views/Authentication/Welcome";
import { ForgotPassword } from "src/views/Authentication/ForgotPassword";
import { WhoYouAre } from "src/views/Authentication/WhoYouAre";
import { EditSchedule } from "src/views/Main/EditSchedule";
import { Conversation } from "src/views/Main/Conversation";
import { Query } from "src/api/generated/types";
import { api } from "src/api";
import { TestCall } from "src/views/Authentication/TestCall";
import { useMe } from "src/hooks";
import LogRocket from "logrocket";
import { NewOnboarding } from "src/views/Authentication/NewOnboarding";

const ProtectedRoute = ({
  authStatus,
  children,
}: {
  authStatus: AuthStatus;
  children: JSX.Element;
}) => {
  const theme = useTheme();

  if (authStatus === "LOADING" || authStatus === "NOT_LOADED") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background,
        }}
      >
        <Spinner color={theme.header} />
      </div>
    );
  }

  if (authStatus === "NOT_LOGGED_IN") {
    const path = window.location.pathname;
    // add the search params if there are any as well
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchString = params.toString();
    return (
      <Navigate
        to={`/login?redirect=${path}${searchString ? "&" + searchString : ""}`}
        replace
      />
    );
  }

  return children;
};

const NoFamilyRedirect = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const authStatus = useSelector(getUserAuthStatus);

  const [getActiveFamily] = useLazyQuery<Pick<Query, "getActiveFamily">>(
    api.families.getActiveFamily,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const _setFamilySlug = async () => {
    const response = await getActiveFamily();

    // console.log(response);

    if (response.data?.getActiveFamily) {
      navigate(`/app/${response?.data?.getActiveFamily?.slug}`);
      return;
    }

    navigate("/login");
  };

  useEffect(() => {
    if (authStatus === "LOGGED_IN") {
      _setFamilySlug();
    } else if (authStatus === "NOT_LOGGED_IN") {
      navigate("/login");
    }
  }, [authStatus]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.background,
      }}
    >
      <Spinner />
    </div>
  );
};

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<NoFamilyRedirect />} />

      <Route path=":familySlug" element={<DashboardContainer />}>
        <Route path="" element={<Dashboard />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="who-are-you" element={<WhoYouAre />} />
        <Route path="test-call" element={<TestCall />} />

        <Route path="members">
          {/* details with an id */}
          <Route path="new" element={<UpsertFamilyMember />} />
          <Route path="onboard" element={<UpsertFamilyMember />} />

          <Route path=":familyMemberId">
            <Route path="" element={<FamilyMember />} />
            <Route path="details" element={<FamilyMember />} />
            <Route path="onboard" element={<OnboardFamilyMember />} />
            <Route path="schedule" element={<EditSchedule />} />

            <Route path="conversations">
              <Route path="" element={<FamilyMember />} />
              <Route path=":conversationId" element={<Conversation />} />
            </Route>

            <Route path="memories">
              <Route path="" element={<FamilyMember />} />
              <Route path=":dailyPromptId" element={<DailyPrompt />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

const MainPage = () => {
  const authStatus = useSelector(getUserAuthStatus);
  const theme = useTheme();

  if (authStatus === "LOGGED_IN") {
    return <Navigate to="/app" />;
  }

  if (authStatus === "NOT_LOGGED_IN") {
    return <Navigate to="/login" />;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.background,
      }}
    >
      <Spinner color={theme.header} />
    </div>
  );
};

const DashboardContainer = () => {
  const authStatus = useSelector(getUserAuthStatus);
  const theme = useTheme();
  const location = useLocation();

  return (
    <ProtectedRoute authStatus={authStatus}>
      <div>
        <Header />
        <div
          style={{
            paddingTop: 70,
            minHeight: "100vh",
            background: `linear-gradient(180deg, ${theme.medBackground} 0%, ${theme.secondaryBackground} 100%)`,
          }}
        >
          <Outlet />
        </div>
      </div>
    </ProtectedRoute>
  );
};

export const MainRoutes = () => {
  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);
  const isLoggedIn = status === "LOGGED_IN";
  const authStatus = useSelector(getUserAuthStatus);
  const { me } = useMe();

  const theme = useTheme();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    // if dark, set the html to have the theme.background color
    document.documentElement.style.backgroundColor = theme.background;
  }, [theme.background]);

  useEffect(() => {
    if (!me) return;

    LogRocket.identify(me.id, {
      name: me.name || "--",
      email: me.email,
    });
  }, [me]);

  useEffect(() => {
    const theme = window.localStorage.getItem("ruth:theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  // return <MaintenanceOverride />;

  return (
    <Routes>
      <Route path="" element={<MainPage />} />
      <Route path="signup" element={<Signup />} />
      <Route path="login" element={<Login />} />
      <Route path="onboarding">
        <Route path="" element={<NewOnboarding />} />
        <Route path="test-call" element={<NewOnboarding />} />
        <Route path="add-to-contacts" element={<NewOnboarding />} />
        <Route path="add-to-contacts-retry" element={<NewOnboarding />} />
        <Route path="who-are-you" element={<NewOnboarding />} />
      </Route>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="logout" element={<Logout />} />
      <Route path="app/*" element={<DashboardRoutes />} />
    </Routes>
  );
};

const Logout = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  useEffect(() => {
    signOut(auth)
      .then(() => apolloClient.cache.reset())
      .then(() => navigate("/login"));
  }, []);

  return null;
};

export const Navigation = () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);
