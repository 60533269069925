import { gql } from "@apollo/client";
import {
  BaseConversationFields,
  BaseDailyPromptFields,
  BaseFamilyFields,
  BaseFamilyMemberFields,
  BasePhoneNumberFields,
  BaseUserFields,
} from "./fragments";

// Cache stuff: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

// === types === //

export enum UserType {
  Verified = "verified",
  Pending = "pending",
}

// ==== users ==== //
const CreateUser = gql`
  ${BaseUserFields}
  mutation CreateUser(
    $name: String!
    $email: String!
    $phoneNumber: String
    $referralCode: String
    $password: String
    $timezone: String
    $isElder: Boolean
  ) {
    createUser(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      password: $password
      timezone: $timezone
      isElder: $isElder
    ) {
      user {
        ...BaseUserFields
      }
      token
    }
  }
`;

const DeleteUser = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

const DeleteMe = gql`
  mutation DeleteMe {
    deleteMe
  }
`;

const UpdateUser = gql`
  ${BaseUserFields}
  mutation UpdateUser($name: String, $hasTwoFactorAuth: Boolean) {
    updateUser(name: $name, hasTwoFactorAuth: $hasTwoFactorAuth) {
      ...BaseUserFields
    }
  }
`;

const UnenrollTwoFactor = gql`
  mutation UnenrollTwoFactor {
    unenrollTwoFactor
  }
`;

const GetMe = gql`
  ${BaseUserFields}
  query GetMe {
    me {
      ...BaseUserFields
    }
  }
`;

const GetPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      last4
      source
    }
  }
`;

const AddFamilyMember = gql`
  ${BaseFamilyMemberFields}
  mutation AddFamilyMember(
    $phoneNumber: String!
    $name: String!
    $familyId: ID!
    $description: String!
    $interestDescription: String
  ) {
    addFamilyMember(
      phoneNumber: $phoneNumber
      name: $name
      familyId: $familyId
      description: $description
      interestDescription: $interestDescription
    ) {
      ...BaseFamilyMemberFields
    }
  }
`;

const UpdateFamilyMember = gql`
  ${BaseFamilyMemberFields}
  mutation UpdateFamilyMember(
    $familyMemberId: ID!
    $name: String
    $description: String
    $nextCallAt: Date
    $phoneNumberId: ID
    $phone: String
    $interestDescription: String
  ) {
    updateFamilyMember(
      familyMemberId: $familyMemberId
      name: $name
      description: $description
      nextCallAt: $nextCallAt
      phoneNumberId: $phoneNumberId
      phone: $phone
      interestDescription: $interestDescription
    ) {
      ...BaseFamilyMemberFields
    }
  }
`;

const UpdateActiveFamilyMember = gql`
  ${BaseFamilyMemberFields}
  mutation UpdateActiveFamilyMember(
    $isElder: Boolean
    $name: String
    $phoneNumber: String
    $biography: String
    $interests: String
  ) {
    updateActiveFamilyMember(
      isElder: $isElder
      name: $name
      phoneNumber: $phoneNumber
      biography: $biography
      interests: $interests
    ) {
      ...BaseFamilyMemberFields
    }
  }
`;

const SetSchedule = gql`
  ${BaseFamilyMemberFields}
  mutation SetSchedule(
    $familyMemberId: ID!
    $timezone: String!
    $shouldAutomaticallyCall: Boolean!
    $timesOfDay: [String!]!
    $daysOfWeek: [Int!]!
  ) {
    setSchedule(
      familyMemberId: $familyMemberId
      timezone: $timezone
      shouldAutomaticallyCall: $shouldAutomaticallyCall
      timesOfDay: $timesOfDay
      daysOfWeek: $daysOfWeek
    ) {
      ...BaseFamilyMemberFields
    }
  }
`;

const GetFamilyPhones = gql`
  ${BasePhoneNumberFields}
  query GetFamilyPhones($familyId: ID!) {
    getFamilyPhones(familyId: $familyId) {
      ...BasePhoneNumberFields
    }
  }
`;

const GetFamilyMembers = gql`
  ${BaseFamilyMemberFields}
  query GetFamilyMembers($familyId: ID!, $onlyElders: Boolean) {
    getFamilyMembers(familyId: $familyId, onlyElders: $onlyElders) {
      ...BaseFamilyMemberFields
    }
  }
`;

const GetActiveFamily = gql`
  ${BaseFamilyFields}
  query getActiveFamily($overrideFamilyId: ID) {
    getActiveFamily(overrideFamilyId: $overrideFamilyId) {
      ...BaseFamilyFields
    }
  }
`;

const GetFamilyBySlug = gql`
  ${BaseFamilyFields}
  query getFamilyBySlug($slug: String!) {
    getFamilyBySlug(slug: $slug) {
      ...BaseFamilyFields
    }
  }
`;

const GetFamilyMemberBySlug = gql`
  ${BaseFamilyMemberFields}
  query getFamilyMemberBySlug($slug: String!) {
    getFamilyMemberBySlug(slug: $slug) {
      ...BaseFamilyMemberFields
    }
  }
`;

const GetActiveFamilyMember = gql`
  ${BaseFamilyMemberFields}
  query GetActiveFamilyMember($overrideFamilyId: ID) {
    getActiveFamilyMember(overrideFamilyId: $overrideFamilyId) {
      ...BaseFamilyMemberFields
    }
  }
`;

const GetFamilyMember = gql`
  ${BaseFamilyMemberFields}
  query GetFamilyMember($familyMemberId: ID!) {
    getFamilyMember(familyMemberId: $familyMemberId) {
      ...BaseFamilyMemberFields
    }
  }
`;

const StartCall = gql`
  mutation StartCall($familyMemberId: ID!, $dailyPromptId: ID) {
    startCall(familyMemberId: $familyMemberId, dailyPromptId: $dailyPromptId) {
      id
    }
  }
`;

const GetConversationsWithPhone = gql`
  ${BaseConversationFields}
  query GetConversationsWithPhone($phoneNumberId: ID!) {
    getConversationsWithPhone(phoneNumberId: $phoneNumberId) {
      ...BaseConversationFields
    }
  }
`;

const GetDailyPrompts = gql`
  ${BaseDailyPromptFields}
  query GetDailyPrompts($familyMemberId: ID!) {
    getDailyPrompts(familyMemberId: $familyMemberId) {
      ...BaseDailyPromptFields
    }
  }
`;

const GetDailyPrompt = gql`
  ${BaseDailyPromptFields}
  query GetDailyPrompt($dailyPromptId: ID!) {
    getDailyPrompt(dailyPromptId: $dailyPromptId) {
      ...BaseDailyPromptFields
    }
  }
`;

const AddDailyPrompt = gql`
  mutation AddDailyPrompt($familyMemberId: ID!, $question: String!) {
    addDailyPrompt(familyMemberId: $familyMemberId, question: $question) {
      id
    }
  }
`;

const UpdateDailyPrompt = gql`
  mutation UpdateDailyPrompt($dailyPromptId: ID!, $question: String!) {
    updateDailyPrompt(dailyPromptId: $dailyPromptId, question: $question) {
      id
    }
  }
`;

const RemoveDailyPrompt = gql`
  mutation RemoveDailyPrompt($dailyPromptId: ID!) {
    removeDailyPrompt(dailyPromptId: $dailyPromptId)
  }
`;

const GetFamilyMemberMetrics = gql`
  ${BaseConversationFields}
  query GetFamilyMemberMetrics($familyMemberId: ID!) {
    getFamilyMemberMetrics(familyMemberId: $familyMemberId) {
      missedCallsTotal
      completedCallsTotal
      callsThisWeek {
        ...BaseConversationFields
      }
      missedCallsThisWeek {
        ...BaseConversationFields
      }
    }
  }
`;

const GetFamilies = gql`
  ${BaseFamilyFields}
  query GetFamilies {
    getFamilies {
      ...BaseFamilyFields
    }
  }
`;

const CreateFamily = gql`
  ${BaseFamilyFields}
  mutation CreateFamily($name: String!) {
    createFamily(name: $name) {
      ...BaseFamilyFields
    }
  }
`;

const SetActiveFamily = gql`
  ${BaseFamilyFields}
  mutation SetActiveFamily($familyId: ID!) {
    setActiveFamily(familyId: $familyId) {
      ...BaseFamilyFields
    }
  }
`;

const GetConversations = gql`
  ${BaseConversationFields}
  query GetConversations($familyMemberId: ID!) {
    getConversations(familyMemberId: $familyMemberId) {
      ...BaseConversationFields
    }
  }
`;

const GetConversation = gql`
  ${BaseConversationFields}
  query GetConversation($conversationId: ID!) {
    getConversation(conversationId: $conversationId) {
      ...BaseConversationFields
    }
  }
`;

const TestCall = gql`
  ${BaseConversationFields}
  mutation TestCall($familyMemberId: ID!) {
    testCall(familyMemberId: $familyMemberId) {
      ...BaseConversationFields
    }
  }
`;

export const api = {
  users: {
    create: CreateUser,
    delete: DeleteUser,
    deleteMe: DeleteMe,
    update: UpdateUser,
    unenrollTwoFactor: UnenrollTwoFactor,
    me: GetMe,
    paymentMethods: GetPaymentMethods,
  },
  families: {
    testCall: TestCall,
    create: CreateFamily,
    setActive: SetActiveFamily,
    list: GetFamilies,
    getMember: GetFamilyMember,
    phones: GetFamilyPhones,
    members: GetFamilyMembers,
    addMember: AddFamilyMember,
    getActiveFamily: GetActiveFamily,
    getActiveFamilyMember: GetActiveFamilyMember,
    bySlug: GetFamilyBySlug,
    memberBySlug: GetFamilyMemberBySlug,
    updateMember: UpdateFamilyMember,
    updateActiveMember: UpdateActiveFamilyMember,
    metrics: GetFamilyMemberMetrics,
    setSchedule: SetSchedule,
  },
  phones: {
    call: StartCall,
  },
  conversations: {
    retrieve: GetConversation,
    forMember: GetConversations,
    listForPhone: GetConversationsWithPhone,
  },
  dailyPrompts: {
    forFamilyMember: GetDailyPrompts,
    add: AddDailyPrompt,
    update: UpdateDailyPrompt,
    remove: RemoveDailyPrompt,
    retrieve: GetDailyPrompt,
  },
};
