import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { getGradientById } from "src/utils/helpers";
import { ActionSheet } from "./ActionSheet";
import { useActiveFamily } from "src/hooks/useActiveFamily";
import { useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import { Mutation, Query } from "src/api/generated/types";
import { Option, Select } from "./Form";
import { Touchable } from "./Touchable";
import { BaseFamilyFields } from "src/api/fragments";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

function Header() {
  const theme = useTheme();
  const toast = useMyToast();

  const { me } = useMe();
  const { familySlug } = useParams<{ familySlug: string }>();
  const { activeFamily } = useActiveFamily();
  const isLarge = useIsLargeScreen();

  // console.log("HEADER", familySlug, activeFamily?.name);

  const { data } = useQuery<Pick<Query, "getFamilies">>(api.families.list);

  const navigation = useNavigate();

  const [createFamily] = useMutation<Pick<Mutation, "createFamily">>(
    api.families.create
  );

  const [setActiveFamily] = useMutation<Pick<Mutation, "setActiveFamily">>(
    api.families.setActive
  );

  const families = data?.getFamilies || [];

  const _createFamily = async () => {
    // ask for the name
    const name = prompt("What is the name of the family?");

    if (!name) {
      return;
    }

    await createFamily({
      variables: {
        name,
      },
      refetchQueries: [
        api.families.list,
        api.families.getActiveFamily,
        api.users.me,
      ],
    });

    toast.show({
      message: "Family created!",
      status: "success",
    });
  };

  const _setActive = async (family: BaseFamilyFields) => {
    await setActiveFamily({
      variables: {
        familyId: family.id,
      },
      refetchQueries: [api.families.getActiveFamily, api.users.me],
    });

    // toast.show({
    //   message: "Family switched!",
    //   status: "success",
    // });

    navigation(`/app/${family.slug}`);
  };

  const options = useMemo(
    () =>
      families.map((f) => ({ label: f.name, value: f.id, family: f })) || [],
    [families]
  );

  return (
    <div
      style={{
        backgroundColor: colors.white,
        zIndex: 100,
        padding: "0 15px",
        position: "fixed",
        borderBottom: `1px solid ${theme.border}`,
        top: 0,
        width: "100%",
        height: 75,
        // box shadow on the bottom
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.02)",
      }}
    >
      <div
        style={{
          maxWidth: "50rem",
          height: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Link to={`/app/${familySlug}`} style={{ cursor: "pointer" }}>
          <Box
            style={{
              flex: 1,
              color: colors.black,
              fontSize: 22,
              minWidth: 100,
              fontWeight: "bold",
              fontFamily: "Spectral",
              overflow: "hidden",
            }}
          >
            {activeFamily?.name || "Family"}
          </Box>
        </Link>

        {isLarge && (
          <Flex
            flex={1}
            style={{
              justifyContent: "flex-start",
            }}
          >
            {/* <NavLink iconName="fa fa-home" label="Home" link="/app" /> */}
            <NavLink
              iconName="fa fa-users"
              label="View my family"
              link={`/app/${familySlug}`}
            />
          </Flex>
        )}

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {families?.length > 1 && (
            <Select
              containerStyle={{ width: 200, marginBottom: 0, marginRight: 10 }}
              selectProps={{
                onChange: (e: any) => _setActive(e.family as BaseFamilyFields),
                value: options.find(
                  (f) => f.family?.slug === activeFamily?.slug
                ),
              }}
              options={options}
            />
          )}

          {me?.isSuperuser && (
            <Touchable onClick={_createFamily} style={{ marginRight: 10 }}>
              <i className="fa fa-plus" style={{ fontSize: 16 }}></i>
            </Touchable>
          )}

          <ProfileIcon />
        </div>
      </div>
    </div>
  );
}

const NavLink = ({
  iconName,
  link,
  numNotifications,
  colorOverride,
  hideNumber,
  label,
}: {
  iconName: any;
  link: string;
  numNotifications?: number;
  colorOverride?: string;
  hideNumber?: boolean;
  label: string;
}) => {
  const theme = useTheme();

  return (
    <Link to={link} style={{ cursor: "pointer" }}>
      <div
        style={{
          padding: 15,
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          textDecoration: "underline",
        }}
      >
        {/* <i className={iconName} style={{ fontSize: 16 }}></i> */}

        <Text
          style={{
            color: theme.header,
            fontWeight: "medium",
            fontSize: "sm",
            marginLeft: 10,
          }}
        >
          {label}
        </Text>

        {!!numNotifications && (
          <Box
            w="1rem"
            h="1rem"
            borderRadius="50%"
            bgColor={colorOverride || colors.red50}
            position="absolute"
            marginLeft="1.25rem"
            marginTop="1.25rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              color={colors.white}
              fontWeight="semibold"
              fontSize="x-small"
              visibility={hideNumber ? "hidden" : undefined}
            >
              {numNotifications}
            </Text>
          </Box>
        )}
      </div>
    </Link>
  );
};

const ProfileIcon = () => {
  const { me } = useMe();
  const theme = useTheme();
  const gradient = getGradientById(me?.id || "");
  const initials = (me?.name || "")
    .split(" ")
    .map((n) => n[0])
    .join("");

  const firstName = (me?.name || "").split(" ")[0];

  return (
    <ActionSheet
      popover={{ placement: "bottom-end" }}
      content={{ width: 275 }}
      topComponent={
        <HStack
          style={{
            marginBottom: "0.25rem",
            padding: "0.5rem 0.5rem 0.75rem 0.5rem",
            justifyContent: "flex-start",
            borderBottom: `1px solid ${theme.border}`,
          }}
        >
          <Text
            style={{ fontSize: 12, color: colors.black, fontWeight: "bold" }}
          >
            Logged in {me?.email}
          </Text>
        </HStack>
      }
      commands={[
        {
          label: "Logout",
          iconName: "fa fa-sign-out",
          iconColor: colors.red50,
          onClick: () => {
            window.location.href = "/logout";
          },
        },
      ]}
    >
      <HStack
        style={{
          padding: 5,
          background: theme.secondaryBackground,
          cursor: "pointer",
          paddingRight: 15,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            background: `linear-gradient(45deg, ${gradient})`,
            width: 35,
            height: 35,
            cursor: "pointer",
            borderRadius: 100,
            color: colors.black,
            fontWeight: "bold",
            fontSize: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {initials}
        </div>

        <Text
          style={{ marginLeft: 0, color: colors.black, fontWeight: "bold" }}
        >
          {firstName}
        </Text>

        <i className="fa fa-caret-down" style={{ fontSize: 12 }}></i>
      </HStack>
    </ActionSheet>
  );
};

export default Header;
