import { Navigation } from "./navigation";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/apollo";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "src/theme";

// Antd partial styles
// import "antd/lib/message/style/css";
// import "antd/lib/notification/style/css";

import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "./config";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "./components/ErrorBoundary";
import LogRocket from "logrocket";

const queryClient = new QueryClient();

if (config.env === "production") {
  LogRocket.init("k66vku/ruth");
}

(window as any).mixpanel?.init("168390d8de2db771932bdeed5e6198c3", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

const App = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={apolloClient}>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <Navigation />
                </PersistGate>
              </Provider>
            </ApolloProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
};

export default App;
