import { useMutation } from "@apollo/client";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseDailyPromptFields } from "src/api/fragments";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { FamilyMemberContext } from "./context";
import { MutationStartCallArgs } from "src/api/generated/types";

export const DailyPromptRow = ({
  dailyPrompt,
}: {
  dailyPrompt: BaseDailyPromptFields;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { familyMember } = useContext(FamilyMemberContext);
  const { familyMemberId, familySlug } = useParams<{
    familyMemberId: string;
    familySlug: string;
  }>();

  const [call] = useMutation(api.phones.call);

  const startCall = async () => {
    try {
      const formattedPhone = familyMember?.formattedPhone;

      const confirmed = window.confirm(
        `This will call the phone number of ${familyMember?.name} ${
          formattedPhone || "-"
        } and have a conversation with them.`
      );

      if (!confirmed) {
        return;
      }

      const variables: MutationStartCallArgs = {
        familyMemberId: familyMemberId || "",
        dailyPromptId: dailyPrompt.id,
      };

      await call({
        variables,
      });
    } catch (err) {
      console.log(err);
      // return
    }
  };

  const _onUpdate = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      show("DailyPromptModal", {
        dailyPrompt,
        familyMemberId: familyMemberId,
      })
    );
  };

  return (
    <Link
      to={`/app/${familySlug}/members/${dailyPrompt.familyMemberId}/memories/${dailyPrompt.id}`}
    >
      <Box
        key={dailyPrompt.id}
        display="flex"
        flexDir="row"
        padding="1.5rem 0"
        borderBottom={`1px solid ${theme.border}`}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <i
        style={{
          marginRight: 15,
          color: colors.gray50,
        }}
        className="fas fa-grip-vertical"
      /> */}

        <div style={{ flex: 1 }}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: "normal",
            }}
          >
            {dailyPrompt.prompt}
          </Text>

          <HStack
            style={{
              marginTop: 15,
              alignItems: "center",
            }}
          >
            {/* <Touchable
              style={{
                marginTop: 0,
                marginRight: 0,
                fontSize: 14,
                background: theme.secondaryBackground,
              }}
              onClick={startCall}
            >
              Call{" "}
              <i
                className="fas fa-phone"
                style={{
                  color: theme.text,
                }}
              />{" "}
            </Touchable> */}

            <Touchable
              style={{
                marginTop: 0,
                fontSize: 14,
                background: theme.secondaryBackground,
              }}
              onClick={_onUpdate}
            >
              Edit{" "}
              <i
                className="fas fa-pen"
                style={{
                  color: theme.text,
                }}
              />
            </Touchable>

            {dailyPrompt.isCompleted ? (
              <Text
                style={{
                  marginLeft: 15,
                  color: colors.positive,
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Completed <i className="fas fa-check-circle" />
              </Text>
            ) : null}
          </HStack>
        </div>

        <Flex
          style={{
            marginRight: 15,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <i
            style={{ color: theme.text, marginLeft: 10 }}
            className="fas fa-chevron-right"
          />
        </Flex>

        {/* <Touchable
          onClick={() => {
            // TODO:
          }}
          style={{
            marginLeft: 10,
          }}
        >
          <i
            style={{
              color: theme.text,
            }}
            className="fas fa-chevron-down"
          />
        </Touchable> */}
      </Box>
    </Link>
  );
};
