import { createContext } from "react";
import {
  BaseConversationFields,
  BaseDailyPromptFields,
  BaseFamilyMemberFields,
} from "src/api/fragments";

export type FamilyMemberActiveTab = "memories" | "conversations" | "details";

type FamilyMemberContext = {
  familyMember: BaseFamilyMemberFields | null;
  dailyPrompts: BaseDailyPromptFields[];
  conversations: BaseConversationFields[];
  activeTab: FamilyMemberActiveTab;
  setActiveTab: (tab: FamilyMemberActiveTab) => void;
};

export const FamilyMemberContext = createContext<FamilyMemberContext>({
  familyMember: null,
  dailyPrompts: [],
  conversations: [],
  activeTab: "details",
  setActiveTab: () => {
    //
  },
});
