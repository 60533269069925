import { Box, Container, HStack } from "@chakra-ui/react";
import React from "react";
import { colors } from "src/theme";

export type MyToastStatus = "info" | "error" | "success" | "warning";

const STATUS_TO_INFO: Record<MyToastStatus, { bg: string; iconName: string }> =
  {
    info: {
      bg: "blue.500",
      iconName: "fa-sharp fa-info-circle",
    },
    warning: {
      bg: "yellow.500",
      iconName: "fa-sharp fa-exclamation-circle",
    },
    error: {
      bg: "red.500",
      iconName: "fa-sharp fa-times-circle",
    },
    success: {
      bg: "green.500",
      iconName: "fa-sharp fa-check-circle",
    },
  };

export const MyToast = ({
  message,
  status,
  onClick,
}: {
  message: string | JSX.Element;
  status: MyToastStatus;
  onClick?: () => void;
}) => {
  const info = STATUS_TO_INFO[status];

  return (
    <Container
      fontSize="md"
      marginTop="0 !important"
      bg={info.bg}
      color={colors.white}
      padding="0.5rem 1rem"
      borderRadius={10}
      onClick={onClick}
      cursor={onClick ? "pointer" : "default"}
    >
      <HStack>
        <i
          className={info.iconName}
          style={{
            position: "relative",
            fontSize: 20,
            margin: "0 0.25rem",
          }}
        />
        <Box>{message}</Box>
      </HStack>
    </Container>
  );
};
