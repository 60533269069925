import React, { useCallback, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  List,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors, other } from "src/theme";
import Confetti from "react-confetti";
import { motion } from "framer-motion";
import { useMe, useMyToast } from "src/hooks";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { isEmpty, isNil } from "lodash/fp";
import { Button } from "src/components/Button";
import { useTheme } from "src/hooks/useTheme";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import parsePhoneNumberFromString, {
  AsYouType,
  parsePhoneNumber,
} from "libphonenumber-js";
import { Input } from "src/components/Form";
import {
  Mutation,
  MutationTestCallArgs,
  MutationUpdateActiveFamilyMemberArgs,
} from "src/api/generated/types";

export function TestCall() {
  const toast = useMyToast();
  const [runConfetti] = useState(false);
  const navigate = useNavigate();
  const { familySlug } = useParams();
  const theme = useTheme();
  const [isForMe, setIsForMe] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [hasCalled, setHasCalled] = useState(false);

  const [testCall] = useMutation<
    Pick<Mutation, "testCall">,
    MutationTestCallArgs
  >(api.families.testCall);

  const [updateActiveMember] = useMutation<
    Pick<Mutation, "updateActiveFamilyMember">,
    MutationUpdateActiveFamilyMemberArgs
  >(api.families.updateActiveMember);

  const _getPhoneNumber = (): string | null => {
    // parse the phone number to E.164
    const rawPhone = phoneNumber || "";

    // if it starts with a +, then it's already in E.164 format
    if (rawPhone.startsWith("+")) {
      return rawPhone;
    }

    // otherwise parse it into E.164 using the US country code
    const parsedPhoneNumber = parsePhoneNumberFromString(rawPhone, "US");

    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      return parsedPhoneNumber.format("E.164");
    }

    // return null if parsing fails or the number is invalid
    return null;
  };

  const _onContinue = async () => {
    // parse the phon number to E.164
    const phoneNumber = _getPhoneNumber();

    if (!phoneNumber) {
      toast.show({
        message: "Invalid phone number",
        status: "error",
      });
      return;
    }

    try {
      const variables: MutationUpdateActiveFamilyMemberArgs = {
        phoneNumber: phoneNumber,
      };

      const activeMemberResponse = await updateActiveMember({
        variables,
      });

      const familyMemberId =
        activeMemberResponse?.data?.updateActiveFamilyMember?.id;

      if (!familyMemberId) {
        toast.show({
          message: "An error occurred. Please try again.",
          status: "error",
        });
        return;
      }

      await testCall({
        variables: {
          familyMemberId: familyMemberId,
        },
      });

      toast.show({
        message: "You will receive a call shortly.",
        status: "success",
      });

      setHasCalled(true);
    } catch (err) {
      toast.show({
        message: "An error occurred. Please try again.",
        status: "error",
      });
    }
  };

  const _onNext = () => {
    navigate(`/app/${familySlug}/who-are-you`);
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <VStack padding="2rem 0" flexDir="column" display="flex">
        <Confetti
          run={runConfetti}
          colors={[
            // colors.blue50,
            // colors.green50,
            // colors.purple50,
            // colors.red50,
            colors.yellow50,
            colors.orange50,
            // colors.pink50,
          ]}
          numberOfPieces={250}
          recycle={false}
        />

        <Box
          display="flex"
          flexDir="column"
          maxWidth="35rem"
          margin="auto"
          padding="2rem"
          h="100%"
          w="100%"
          borderRadius={other.borderRadius}
          bg={colors.white}
          border={other.boxBorder}
        >
          <Box width="100%">
            <Text
              className="spectral-bold"
              style={{
                fontSize: 24,
                fontWeight: "bold",
              }}
              marginBottom="0.25rem"
            >
              Try out Quickfix
            </Text>

            <Box width="100%">
              <Text
                style={{
                  fontSize: 18,
                  color: theme.text,
                  fontWeight: "normal",
                }}
                marginBottom="2rem"
              >
                Chat with Quickfix for a few minutes to test her out. You can
                hangup at any time.
              </Text>
            </Box>

            {/* two options, can click a circle and color it in. me or "for someone else" */}

            <div>
              <Input
                isRequired
                label={"Phone number"}
                subtitle="🛡️ Your security is our priority. We will never share this number with anyone."
                placeholder="Phone Number"
                value={new AsYouType("US").input(phoneNumber || "")}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <br />
            <br />

            <Button
              onClick={_onContinue}
              padding="1rem 0.5rem"
              disabled={runConfetti}
              variant={hasCalled ? "secondary" : "primary"}
              style={{ width: "100%" }}
            >
              Call me{" "}
              <i style={{ marginLeft: 15 }} className="fa-sharp fa-phone-alt" />
            </Button>

            {hasCalled && (
              <Button
                marginTop={15}
                onClick={_onNext}
                padding="1rem 0.5rem"
                variant={hasCalled ? "primary" : "secondary"}
                style={{ width: "100%" }}
              >
                Continue
              </Button>
            )}

            <Text
              color={theme.text}
              style={{ marginTop: 25 }}
              fontSize="md"
              textAlign="center"
            >
              <Link to={`/app/${familySlug}/who-are-you`}>Or skip for now</Link>
            </Text>
          </Box>
        </Box>
      </VStack>
    </div>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 35,
          height: 35,
          backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 18,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.gray30,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text fontSize="md" fontWeight="600">
          {header}
        </Text>
        <Text marginTop="0 !important" fontSize="sm">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};

const Option = ({
  onSelect,
  label,
  subtitle,
  isActive,
}: {
  onSelect: () => void;
  label: string;
  subtitle?: string;
  isActive: boolean;
}) => {
  const theme = useTheme();

  return (
    <HStack style={{ marginBottom: 15, cursor: "pointer" }} onClick={onSelect}>
      <div
        style={{
          backgroundColor: isActive ? colors.primary : theme.ternaryBackground,
          width: 30,
          height: 30,
          borderRadius: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isActive ? (
          <i className="fa-sharp fa-check" style={{ color: colors.white }} />
        ) : null}
      </div>

      <div
        style={{
          flex: 1,
        }}
      >
        <Text className="spectral-semibold" fontSize="lg" fontWeight="600">
          {label}
        </Text>

        <Text className="spectral-semibold" fontSize="sm" fontWeight="400">
          {subtitle}
        </Text>
      </div>
    </HStack>
  );
};
