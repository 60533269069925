import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { api } from "src/api";
import { BaseFamilyFields, BaseUserFields } from "src/api/fragments";
import { Query } from "src/api/generated/types";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { AuthStatus } from "src/redux/types";
import { useActiveFamily } from "./useActiveFamily";

type RefetchMeResponse = FailureOrSuccess<
  DefaultErrors,
  ApolloQueryResult<{
    me: BaseUserFields;
  }>
>;

export type UseMeReturn = {
  me: Maybe<BaseUserFields>;
  authStatus: AuthStatus;
  loadingMe: boolean;
  activeFamily: Maybe<BaseFamilyFields>;
  refetchMe: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<RefetchMeResponse>;
};

export const useMe = (
  fetchPolicy: WatchQueryFetchPolicy = "no-cache"
): UseMeReturn => {
  const authStatus = useSelector(getUserAuthStatus);

  const {
    data: myData,
    refetch: _refetchMe,
    loading: loadingMe,
  } = useQuery<{
    me: BaseUserFields;
  }>(api.users.me, {
    fetchPolicy,
    skip: authStatus !== "LOGGED_IN",
  });

  const { activeFamily } = useActiveFamily();

  const refetchMe = async (): Promise<RefetchMeResponse> => {
    try {
      return success(await _refetchMe());
    } catch (err) {
      return failure(new UnexpectedError(err));
    }
  };

  const me = myData?.me || null;

  return {
    me,
    authStatus,
    refetchMe,
    loadingMe,
    activeFamily: activeFamily || null,
  };
};
