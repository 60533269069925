import React, { CSSProperties } from "react";
import { Box, BoxProps, HStack, Text, Tooltip } from "@chakra-ui/react";
import { colors } from "src/theme";
import { isMobile } from "react-device-detect";
import { Info } from "./Info";
import { Copy } from "./Copy";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";

export type StatusTagType =
  | "info"
  | "success"
  | "warning"
  | "error"
  | "none"
  | "brown"
  | "beta";

type Props = {
  type: StatusTagType;
  label?: string | JSX.Element;
  infoMessage?: string;
  infoIcon?: boolean;
  boxProps?: BoxProps;
  iconName?: string;
  className?: string;
  iconStyle?: CSSProperties;
  color?: string;
  copyValue?: Maybe<string>;
  labelStyle?: CSSProperties;
  onClick?: (e?: any) => void | Promise<void>;
  rightIcon?: JSX.Element;
  infoPlacement?: "top" | "bottom";
  hasBorder?: boolean;
};

const TAG_TYPE_TO_COLOR: Record<StatusTagType, string> = {
  info: colors.lightBlue60,
  success: colors.green50,
  warning: colors.yellow40,
  error: colors.red50,
  none: colors.gray30,
  brown: colors.brown30,
  beta: colors.pink40,
};

const TAG_TYPE_TO_BG: Record<StatusTagType, string> = {
  info: colors.lightBlue90,
  success: colors.green90,
  warning: colors.yellow90,
  error: colors.red100,
  none: colors.gray90,
  brown: colors.brown90,
  beta: colors.pink90,
};

const TAG_TYPE_TO_DARK_COLOR: Record<StatusTagType, string> = {
  info: colors.lightBlue60,
  success: colors.green50,
  warning: colors.yellow50,
  error: colors.red50,
  none: colors.gray50,
  brown: colors.brown30,
  beta: colors.pink40,
};

const TAG_TYPE_TO_BG_DARK_MODE: Record<StatusTagType, string> = {
  info: colors.lightBlue20,
  success: colors.green10,
  warning: colors.yellow05,
  error: colors.red10,
  none: colors.gray10,
  brown: colors.brown20,
  beta: colors.pink10,
};

export default function StatusTag({
  label,
  type,
  infoMessage,
  infoIcon = false,
  boxProps,
  className,
  iconName,
  iconStyle,
  color: _color,
  copyValue = null,
  labelStyle,
  rightIcon,
  infoPlacement,
  onClick,
  hasBorder,
}: Props) {
  const { theme } = useTheme();
  const color =
    _color ||
    (theme === "dark" ? TAG_TYPE_TO_DARK_COLOR[type] : TAG_TYPE_TO_COLOR[type]);
  const bg =
    theme === "dark" ? TAG_TYPE_TO_BG_DARK_MODE[type] : TAG_TYPE_TO_BG[type];
  const hasLabel = !!label;

  const Tag = (
    <Box style={{ display: "inline-block" }} onClick={onClick}>
      <HStack
        {...boxProps}
        style={{
          flexWrap: "nowrap",
          borderRadius: 7,
          alignSelf: "center",
          padding: "5px 10px",
          backgroundColor: bg,
          justifyContent: "center",
          alignItems: "center",
          color,
          border: hasBorder ? `1px solid ${color}` : "none",
          ...boxProps?.style,
        }}
        className={className}
      >
        {!hasLabel ? null : (
          <Text
            style={{
              borderWidth: 0,
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "center",
              color,
              display: "inline-block",
              ...labelStyle,
            }}
            isTruncated
          >
            {label}
          </Text>
        )}

        {infoIcon && infoMessage && (
          <Info
            style={{ marginLeft: 0, fontSize: 12, color }}
            message={infoMessage}
          />
        )}

        {iconName && (
          <i
            className={iconName}
            style={{ color, marginRight: 0, ...iconStyle }}
          />
        )}

        {copyValue && (
          <Copy
            labelStyle={{ display: "inline" }}
            iconStyle={{ fontSize: 12, padding: 0 }}
            value={copyValue || ""}
          />
        )}

        {rightIcon}
      </HStack>
    </Box>
  );

  if (!infoIcon && infoMessage) {
    return (
      <Tooltip<any>
        zIndex={1401}
        trigger={isMobile ? "click" : "hover"}
        bg={colors.black}
        placement={infoPlacement || "top"}
        borderRadius="0.25rem"
        label={infoMessage}
        padding={{ base: "0.5rem 1rem" }}
        maxWidth={300}
      >
        {Tag}
      </Tooltip>
    );
  }

  return Tag;
}
