import { useRef, useState } from "react";
import { isSafari } from "react-device-detect";
import { failure, UnexpectedError } from "src/core";

// type DownloadFileProps = {};

interface DownloadedFileInfo {
  readonly download: (url: string) => Promise<any>;
}

export const useDownloadFile = (): DownloadedFileInfo => {
  const download = async (url: string, name?: string) => {
    try {
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", url);
      link.target = "_blank";

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (error) {
      return failure(new UnexpectedError(error));
    }
  };

  return { download };
};
