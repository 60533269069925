import { useParams } from "react-router-dom";
import { useMe } from "./useMe";
import { useMemo } from "react";
import {
  Query,
  QueryGetActiveFamilyArgs,
  QueryGetFamilyBySlugArgs,
  QueryGetFamilyMembersArgs,
} from "src/api/generated/types";
import { useQuery } from "@apollo/client";
import { api } from "src/api";

export const useActiveFamily = () => {
  const { familySlug } = useParams<{ familySlug: string }>();

  const familyVariables = useMemo(
    (): QueryGetFamilyBySlugArgs => ({
      slug: familySlug || "",
    }),
    [familySlug]
  );

  const { data } = useQuery<Pick<Query, "getFamilyBySlug">>(
    api.families.bySlug,
    {
      fetchPolicy: "cache-and-network",
      variables: familyVariables,
      skip: !familyVariables.slug,
    }
  );

  const { data: dataActivityFamilyMember } = useQuery<
    Pick<Query, "getActiveFamilyMember">
  >(api.families.getActiveFamilyMember);

  const activeFamily = useMemo(() => data?.getFamilyBySlug, [data]);
  const activeFamilyMember = useMemo(
    () => dataActivityFamilyMember?.getActiveFamilyMember,
    [dataActivityFamilyMember]
  );

  const variables = useMemo(
    (): QueryGetFamilyMembersArgs => ({
      familyId: activeFamily?.id || "",
    }),
    [activeFamily]
  );

  const { data: familyMemberData } = useQuery<Pick<Query, "getFamilyMembers">>(
    api.families.members,
    {
      variables,
      fetchPolicy: "cache-and-network",
      skip: !variables.familyId,
    }
  );

  const familyMembers = familyMemberData?.getFamilyMembers ?? [];

  return {
    activeFamily,
    activeFamilyMember,
    familyMembers,
  };
};
