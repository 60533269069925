import { config } from "src/config";

const fbq = (window as any).fbq;

export enum FacebookEventName {
  Signup = "CompleteRegistration",
  Subscribe = "Subscribe",
  AddPaymentInfo = "AddPaymentInfo",
}

//  {value: '0.00', currency: 'USD', predicted_ltv: '0.00'}

const track = (eventName: FacebookEventName, params?: any) => {
  if (config.env !== "production") {
    return;
  }

  fbq("track", eventName, params);
};

export const pixel = {
  track,
};
