import {
  Box,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  Text,
} from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { colors, other } from "src/theme";
import { Controller, Control, UseFormRegisterReturn } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "../Info";
import { Required } from "./Required";
import { useTheme } from "src/hooks/useTheme";

type MyInputProps = {
  label?: string;
  labelIconName?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  ref?: React.LegacyRef<HTMLInputElement> | undefined;
  register?: UseFormRegisterReturn;
  iconLeft?: JSX.Element;
  subtitle?: string | JSX.Element;
} & Omit<InputProps, "name">;

export const Input = ({
  containerStyle,
  infoMessage,
  register,
  labelIconName,
  iconLeft,
  ...props
}: MyInputProps) => {
  const theme = useTheme();

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  return (
    <Box style={{ marginBottom: "1.5rem", ...containerStyle }}>
      {props.label && (
        <FormLabel
          marginBottom="6px"
          color={theme.header}
          fontSize={16}
          className="spectral-semibold"
        >
          {labelIconName && (
            <i style={{ marginRight: 5 }} className={labelIconName} />
          )}
          {props.label} {props.isRequired && <Required />}
          {infoMessage && <Info message={infoMessage} />}
        </FormLabel>
      )}

      {props.subtitle && (
        <Text color={theme.text} fontSize={14} marginBottom="1rem">
          {props.subtitle}
        </Text>
      )}

      {props.control && props.name ? (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field }) => {
            const { value, onChange, onBlur, ref } = field;
            return (
              <InputGroup>
                {iconLeft && (
                  <InputLeftElement
                    style={{ padding: "0 0.25rem" }}
                    pointerEvents="none"
                    children={iconLeft}
                  />
                )}
                <ChakraInput
                  {...field}
                  flex={1}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  color={theme.header}
                  value={value}
                  bg={theme.background}
                  borderColor={theme.border}
                  focusBorderColor={colors.primary}
                  {...props}
                />
              </InputGroup>
            );
          }}
        />
      ) : (
        <InputGroup>
          {iconLeft && (
            <InputLeftElement pointerEvents="none" children={iconLeft} />
          )}
          <ChakraInput
            flex={1}
            value={props.value}
            focusBorderColor={colors.primary}
            bg={theme.background}
            color={theme.header}
            borderColor={theme.border}
            {...register}
            {...props}
          />
        </InputGroup>
      )}
    </Box>
  );
};
