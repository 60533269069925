import React, { useCallback, useEffect } from "react";
import { Box, Center, Text, VStack, HStack } from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddFamilyMemberArgs,
  MutationUpdateActiveFamilyMemberArgs,
  Query,
} from "src/api/generated/types";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input, Textarea } from "src/components/Form";
import { Button } from "src/components/Button";
import parsePhoneNumberFromString, {
  AsYouType,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useActiveFamily } from "src/hooks/useActiveFamily";

export function UpsertFamilyMember() {
  const navigate = useNavigate();

  const { activeFamily } = useMe();
  const activeFamilyId = activeFamily?.id;
  const { familySlug } = useParams();

  // onboarding the active family member
  const isOnboard = window.location.href.includes("onboard");

  const theme = useTheme();

  const { activeFamilyMember } = useActiveFamily();

  const familyMember = activeFamilyMember ?? null;

  // API hooks
  const [addFamilyMember] = useMutation<Pick<Mutation, "addFamilyMember">>(
    api.families.addMember
  );

  const [updateActiveMember] = useMutation<
    Pick<Mutation, "updateActiveFamilyMember">
  >(api.families.updateActiveMember);

  const familyMemberToUse = isOnboard ? familyMember : null;

  const [name, setName] = React.useState<string | null>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>("");
  // const [biography, setBiography] = React.useState<string | null>("");
  const [interests, setInterests] = React.useState<string | null>("");
  const [hasName, setHasName] = React.useState<boolean>(false);

  // use effect to set the above if the family member loaded changed (stringified)
  useEffect(() => {
    setName(familyMemberToUse?.name || "");

    if (familyMemberToUse?.name) {
      setHasName(true);
    }

    if (familyMemberToUse?.phone) {
      // parse it into formatted
      const parsedPhoneNumber = parsePhoneNumber(
        familyMemberToUse?.phone,
        "US"
      );
      setPhoneNumber(parsedPhoneNumber?.formatNational() || "");
    }
    // setBiography(familyMemberToUse?.description || "");
    setInterests(familyMemberToUse?.interestDescription || "");
  }, [JSON.stringify(familyMemberToUse)]);

  const _getPhoneNumber = (): string | null => {
    // parse the phone number to E.164
    const rawPhone = phoneNumber || "";

    // if it starts with a +, then it's already in E.164 format
    if (rawPhone.startsWith("+")) {
      return rawPhone;
    }

    // otherwise parse it into E.164 using the US country code
    const parsedPhoneNumber = parsePhoneNumberFromString(rawPhone, "US");

    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      return parsedPhoneNumber.format("E.164");
    }

    // return null if parsing fails or the number is invalid
    return null;
  };

  const _addNewMember = async () => {
    if (!activeFamily) {
      alert("Please create a family first.");
      return;
    }

    if (!name || !phoneNumber) {
      alert("Please fill out all fields");
      return;
    }

    try {
      const phoneNumber = _getPhoneNumber();

      if (!phoneNumber) {
        alert("Please enter a valid phone number");
        return;
      }

      const variables: MutationAddFamilyMemberArgs = {
        familyId: activeFamilyId || "",
        name,
        phoneNumber,
        description: "",
        // description: biography || "",
        interestDescription: interests,
      };

      const response = await addFamilyMember({
        variables: variables,
        refetchQueries: [api.families.getActiveFamily, api.families.members],
      });

      const familyMemberId = response?.data?.addFamilyMember?.id;

      navigate(`/app/${familySlug}/members/${familyMemberId}/schedule`);
    } catch (err) {
      console.log(err);
    }
  };

  const _updateNewUser = async () => {
    if (!name || !phoneNumber) {
      alert("Please fill out all fields");
      return;
    }

    try {
      const phoneNumber = _getPhoneNumber();

      if (!phoneNumber) {
        alert("Please enter a valid phone number");
        return;
      }

      const variables: MutationUpdateActiveFamilyMemberArgs = {
        name,
        phoneNumber,
        biography: "",
        // biography: biography || "",
        interests: interests || "",
      };

      const response = await updateActiveMember({
        variables: variables,
        refetchQueries: [api.families.getActiveFamily, api.families.members],
      });

      const familyMemberId = response?.data?.updateActiveFamilyMember?.id;

      navigate(`/app/${familySlug}/members/${familyMemberId}/schedule`);
    } catch (err) {
      console.log(err);
    }
  };

  // addMember
  const _onSubmit = async () => {
    if (isOnboard) {
      await _updateNewUser();
    } else {
      await _addNewMember();
    }
  };

  return (
    <div style={{}}>
      <VStack padding="2rem 0" flexDir="column" display="flex">
        <Box
          display="flex"
          flexDir="column"
          maxWidth="40rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <Text
            className="spectral-bold"
            style={{
              color: theme.header,
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            {isOnboard
              ? "Tell us a little more about yourself"
              : "Add a family member"}
          </Text>

          <br />

          {!hasName && (
            <Input
              isRequired
              label={isOnboard ? "What's your name" : "What's their name"}
              placeholder="Name"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
            />
          )}

          <Input
            isRequired
            label={
              isOnboard
                ? "What's your phone number"
                : "What's their phone number"
            }
            subtitle="🛡️ Your security is our priority. We will never share this number with anyone."
            placeholder="Phone Number"
            value={new AsYouType("US").input(phoneNumber || "")}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          {/* <Textarea
            label={
              isOnboard ? "Tell us about yourself" : "Tell us about this person"
            }
            // subtitle="We'll help generate specific questions to ask your family member."
            placeholder=""
            value={biography || ""}
            onChange={(e) => setBiography(e.target.value)}
          /> */}

          {/* <Textarea
            label={
              isOnboard
                ? "What topics are you interested in? (optional)"
                : "What topics are they interested in? (optional)"
            }
            subtitle="Ex. history, politics, sports"
            placeholder=""
            value={interests || ""}
            onChange={(e) => setInterests(e.target.value)}
          /> */}

          <br />

          <Button variant="primary" onClick={_onSubmit}>
            Continue
          </Button>
        </Box>
      </VStack>
    </div>
  );
}
