import { HStack, VStack } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { colors } from "src/theme";

const AudioPlayer = ({ url }: { url: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    const updateDuration = () => {
      if (!audio) return;
      setDuration(audio.duration);
    };

    const updateCurrentTime = () => {
      if (!audio) return;
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("timeupdate", updateCurrentTime);

    return () => {
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("timeupdate", updateCurrentTime);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (e: any) => {
    if (!audioRef.current) return;

    const progressBar = e.target;
    const newTime =
      (e.nativeEvent.offsetX / progressBar.offsetWidth) * duration;
    audioRef.current.currentTime = newTime;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div
      style={{
        ...styles.player,
        marginTop: 30,
        maxWidth: 300,
      }}
    >
      <audio ref={audioRef} src={url} />

      <HStack style={{ width: "100%" }}>
        <button onClick={togglePlayPause} style={styles.playButton}>
          <i
            style={{
              position: "relative",
              left: isPlaying ? "0" : "2px",
            }}
            className={isPlaying ? "fas fa-pause" : "fas fa-play"}
          ></i>
        </button>

        <VStack style={{ width: "100%", alignItems: "flex-start" }}>
          <div style={styles.progressBar} onClick={handleProgressClick}>
            <div
              style={{
                ...styles.progress,
                width: `${(currentTime / duration) * 100}%`,
              }}
            />
          </div>
          <div style={styles.time}>
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
        </VStack>
      </HStack>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  player: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: 5,
  },
  playButton: {
    height: 45,
    width: 45,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 22,
    marginBottom: "10px",
    borderRadius: "5px",
    marginRight: 5,
    border: "none",
    backgroundColor: colors.primary,
    color: "#fff",
    cursor: "pointer",
  },
  progressBar: {
    width: "100%",
    height: 6,
    backgroundColor: "#ddd",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
  },
  progress: {
    height: "100%",
    backgroundColor: colors.primary,
    borderRadius: "5px",
  },
  time: {
    marginTop: 2,
    fontSize: 14,
    color: "#333",
  },
};

export default AudioPlayer;
