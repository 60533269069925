import * as colors from "src/theme/colors";

// Borders
export const buttonBorderWidth = "4px";
export const borderRadius = "25px";
export const borderColor = colors.gray80;
export const boxBorder = `1px solid ${borderColor}`;
export const boxShadow = "0px 3px 20px rgb(0 0 0 / 10%)"; // modified dribbble's beautiful search bar
export const lighterBoxShadow = "0px 1px 1px rgb(0 0 0 / 5%)"; // modified dribbble's beautiful search bar

// Button Group
export const buttonMargin = "1.5rem";
export const buttonSpacing = "4";

// Scroll
export const scrollBuffer = 50;

// Blur
export const blurTextShadow = "0 0 0.5rem #000";
