import { useToast } from "@chakra-ui/react";
import { MyToast, MyToastStatus } from "src/components/MyToast";

export type ToastType = {
  show: ({ message, status }: ShowToastFunctionType) => void;
};

export type ShowToastFunctionType = {
  message: string;
  status: MyToastStatus;
  durationMS?: number;
  onClick?: () => void;
};

export const useMyToast = () => {
  const toast = useToast();

  const showToast = ({
    message,
    status,
    durationMS,
    onClick,
  }: ShowToastFunctionType) => {
    toast({
      position: "top",
      duration: durationMS ?? 5 * 1000,
      render: () => (
        <MyToast onClick={onClick} status={status} message={message} />
      ),
    });
  };

  return {
    show: showToast,
  };
};
