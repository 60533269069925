import { useMutation } from "@apollo/client";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseConversationFields } from "src/api/fragments";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { FamilyMemberContext } from "./context";
import {
  ConversationStatusEnum,
  MutationStartCallArgs,
} from "src/api/generated/types";
import moment from "moment";
import StatusTag from "src/components/StatusTag";

export const ConversationRow = ({
  conversation,
}: {
  conversation: BaseConversationFields;
}) => {
  const { familySlug } = useParams();
  const theme = useTheme();
  const { familyMember } = useContext(FamilyMemberContext);

  const status =
    conversation.status === ConversationStatusEnum.Missed
      ? "error"
      : conversation.status === ConversationStatusEnum.Completed
      ? "success"
      : conversation.status === ConversationStatusEnum.InProgress
      ? "warning"
      : "none";

  return (
    <Link
      to={`/app/${familySlug}/members/${familyMember?.id}/conversations/${conversation.id}`}
    >
      <Box
        display="flex"
        flexDir="row"
        padding="1.5rem 0"
        borderBottom={`1px solid ${theme.border}`}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: "normal",
            }}
          >
            Call @ {moment(conversation.createdAt).format("MMM Do, h:mma")}
          </Text>
        </div>

        <Flex>{conversation.durationFormatted || "-"}</Flex>

        <Flex
          style={{
            marginRight: 25,
            marginLeft: 25,
            justifyContent: "flex-end",
            width: 125,
          }}
        >
          <StatusTag type={status} label={conversation.status} />
        </Flex>

        <Flex
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <i
            style={{ color: theme.text, marginLeft: 10 }}
            className="fas fa-chevron-right"
          />
        </Flex>

        {/* <Touchable
          onClick={() => {
            // TODO:
          }}
          style={{
            marginLeft: 10,
          }}
        >
          <i
            style={{
              color: theme.text,
            }}
            className="fas fa-chevron-down"
          />
        </Touchable> */}
      </Box>
    </Link>
  );
};
