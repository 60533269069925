import {
  Box,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PopoverProps,
  ModalFooterProps,
  ModalBodyProps,
  ModalHeaderProps,
  HeadingProps,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { colors } from "src/theme";
import { CommandProps } from "./ActionSheet/Command";
import { Touchable } from "./Touchable";
import { other } from "src/theme";
import { CSSProperties } from "react";
import { useTheme } from "src/hooks/useTheme";
import { ActionSheet } from "./ActionSheet";

type ModalProps = Omit<ChakraModalProps, "isOpen" | "onClose"> & {
  children: JSX.Element | JSX.Element[];
  Footer?: JSX.Element;
  title?: string | JSX.Element;
  isVisible?: boolean;
  minW?: string;
  minH?: string;
  maxW?: string;
  w?: string;
  h?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  popoverCommands?: CommandProps[];
  modalColor?: string;
  footerProps?: ModalFooterProps;
  bodyProps?: ModalBodyProps;
  headerProps?: ModalHeaderProps;
  titleHeaderProps?: HeadingProps;
  contentStyle?: CSSProperties;
  modalOverlayColor?: string;
  overflow?: string;
  hideClose?: boolean;
  marginTop?: string;
  handleHide: () => void;
};

export const Modal = ({
  children,
  isVisible = true,
  title,
  Footer,
  minW,
  minH,
  popoverCommands,
  modalColor,
  handleHide,
  w,
  maxW,
  h,
  display,
  alignItems,
  justifyContent,
  footerProps,
  bodyProps,
  headerProps,
  titleHeaderProps,
  hideClose = false,
  marginTop,
  modalOverlayColor: _modalOverlayColor,
  overflow,
  contentStyle,
  ...others
}: ModalProps) => {
  const { theme, header, background, border } = useTheme();
  const modalOverlayColor =
    theme === "light" ? "rgba(30,30,30,0.5)" : "rgba(0,0,0,0.85)";

  return (
    <ChakraModal
      {...others}
      blockScrollOnMount={false}
      size="lg"
      isOpen={isVisible}
      onClose={handleHide}
    >
      <ModalOverlay bgColor={modalOverlayColor} />
      <ModalContent
        minW={minW}
        minH={minH}
        w={w}
        h={h}
        maxW={maxW}
        display={display}
        bgColor={modalColor || background}
        marginTop={marginTop || "1rem !important"}
        style={{
          border: "1px solid " + border,
          marginTop: isMobile ? 0 : "6rem",
          boxShadow: other.boxShadow,
          ...contentStyle,
        }}
        overflow={overflow || "hidden"}
      >
        {popoverCommands && (
          <Box position="absolute" width="100%" margin="0">
            <Box
              width="1rem"
              display="flex"
              marginTop="0.55rem"
              float="right"
              marginRight="3.5rem"
            >
              <ActionSheet
                popover={{ placement: "bottom-end" }}
                content={{ width: 200 }}
                commands={popoverCommands}
              >
                <Touchable iconName="fa-sharp fa-ellipsis-v" />
              </ActionSheet>
            </Box>
          </Box>
        )}
        {title && (
          <ModalHeader {...headerProps} color={header} fontSize={26}>
            <Heading
              marginTop="0.8rem"
              size="lg"
              marginBottom="0"
              color={header}
              {...titleHeaderProps}
            >
              {title}
            </Heading>
          </ModalHeader>
        )}

        <ModalBody {...bodyProps}>{children}</ModalBody>

        {Footer && <ModalFooter {...footerProps}>{Footer}</ModalFooter>}
        {hideClose === true ? null : (
          <ModalCloseButton color={theme === "light" ? "black" : "white"} />
        )}
      </ModalContent>
    </ChakraModal>
  );
};
