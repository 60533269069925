import {
  Button,
  Container,
  Text,
  UnorderedList,
  ListItem,
  Link,
  HStack,
  Switch,
} from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { Modal } from "../Modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationSetScheduleArgs,
  Query,
} from "src/api/generated/types";
import { Input, Option, Select } from "../Form";
import { useMyToast } from "src/hooks";
import { BaseFamilyMemberFields } from "src/api/fragments";
import moment from "moment";
import { Touchable } from "../Touchable";
import { colors } from "src/theme";

const DAY_OPTIONS = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

const TIMEZONES = [
  { label: "Eastern Time", value: "America/New_York" },
  { label: "Central Time", value: "America/Chicago" },
  { label: "Mountain Time", value: "America/Denver" },
  { label: "Pacific Time", value: "America/Los_Angeles" },
];

type Props = InjectedProps & {
  familyMember: BaseFamilyMemberFields;
};

function _ScheduleModal({ handleHide, show: isVisible, familyMember }: Props) {
  const DEFAULT_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const theme = useTheme();
  const toast = useMyToast();

  const [time, setTime] = useState<string>(
    (familyMember?.timesOfDay ?? [])[0] ?? ""
  );
  const [days, setDays] = useState<number[]>(familyMember?.daysOfWeek ?? []);
  const [timezone, setTimezone] = useState<string>(
    familyMember?.timezone !== "UTC" ? familyMember?.timezone : DEFAULT_TZ
  );
  // const [shouldAutomaticallyCall, setShouldAutomaticallyCall] =
  //   useState<boolean>(
  //     familyMember ? familyMember.shouldAutomaticallyCall ?? false : true
  //   );

  const [setSchedule] = useMutation<
    Pick<Mutation, "setSchedule">,
    MutationSetScheduleArgs
  >(api.families.setSchedule);

  const _updateSchedule = async () => {
    // the timezone of the browser

    if (!familyMember) {
      return toast.show({
        message: "No family member found.",
        status: "error",
      });
    }

    const variables: MutationSetScheduleArgs = {
      familyMemberId: familyMember.id,
      timesOfDay: [time],
      daysOfWeek: days,
      timezone: timezone,
      shouldAutomaticallyCall: true,
    };

    // console.log(variables);

    const response = await setSchedule({
      variables,
      refetchQueries: [api.families.members, api.families.getMember],
    });

    toast.show({
      message: "Updated schedule of family member.",
      status: "success",
    });

    handleHide();
  };

  const _onSave = async () => {
    try {
      if (familyMember) {
        await _updateSchedule();
      }
    } catch (err) {
      toast.show({
        message: "An error occurred while updating the schedule.",
        status: "error",
      });
    }
  };

  const selectedValue = TIMEZONES.find(
    (o) => o.value?.toLowerCase() === timezone?.toLowerCase()
  );

  console.log(selectedValue, timezone);

  return (
    <Modal
      title={`${familyMember?.name} Schedule`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={_onSave}
        >
          Save
        </Button>
      }
    >
      {/* Time */}
      <Input
        label="Time"
        autoFocus={true}
        type="time"
        value={time || ""}
        placeholder="11:45am"
        onChange={(e) => setTime(e.target.value)}
      />

      {/* Days of the Week */}
      <Select
        options={DAY_OPTIONS}
        label="Days of the Week"
        containerStyle={{ marginBottom: 5 }}
        selectProps={{
          isMulti: true,
          onChange: (selected: any) => {
            if (!selected) {
              setDays([]);
              return;
            }

            setDays(selected.map((s: Option) => s.value));
          },
          value: days.map((d) => DAY_OPTIONS.find((o) => o.value === d)),
        }}
      />

      <Touchable
        style={{
          marginTop: 0,
          padding: 0,
          background: "none",
          display: "inline-block",
          textDecoration: "underline",
        }}
        onClick={() => {
          setDays([0, 1, 2, 3, 4, 5, 6]);
        }}
      >
        Add all days
      </Touchable>

      <br />
      <br />

      {/*  timezone selector */}
      <Select
        options={TIMEZONES}
        label="Timezone"
        selectProps={{
          onChange: (selected: any) => {
            setTimezone(selected.value);
          },
          value: selectedValue,
        }}
      />

      {/* toggle for if scheudle is active */}
      {/* <HStack>
        <Text fontSize="md" color={theme.text} style={{ flex: 1 }}>
          Automatically call {familyMember?.name}
        </Text>

       <Switch
          isChecked={shouldAutomaticallyCall}
          onChange={(v) => setShouldAutomaticallyCall(v.target.checked)}
        />
      </HStack> */}

      {/* {!shouldAutomaticallyCall ? (
        <Text
          fontSize="md"
          style={{ marginTop: 1, color: colors.red50 }}
          color={theme.text}
        >
          We will no longer call {familyMember?.name} automatically from the
          schedule you set above.
        </Text>
      ) : (
        <div />
      )} */}
    </Modal>
  );
}

export const ScheduleModal = connectModal({
  name: "ScheduleModal",
})(_ScheduleModal);
