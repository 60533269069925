import React, { useCallback, useEffect } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Heading,
  List,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors, other } from "src/theme";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api, apolloClient } from "src/api";
import {
  Mutation,
  MutationCreateUserArgs,
  MutationTestCallArgs,
  MutationUpdateActiveFamilyMemberArgs,
  Query,
} from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import { BaseUserFields } from "src/api/fragments";
import { signInWithCustomToken } from "firebase/auth";
import { getRedirectPath } from "../utils";
import { GoogleButton } from "../components/Google";
import { useMyToast } from "src/hooks";
import { isNil } from "lodash/fp";
import { AppleButton } from "../components/Apple";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { FacebookEventName, pixel } from "src/utils/pixel";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import parsePhoneNumberFromString, { AsYouType } from "libphonenumber-js";
import { useLocalStorage } from "src/hooks/common";
import { ShareRuthContactModal } from "src/components/modals/ShareRuthContactModal";
import { show } from "redux-modal";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SignupImage = require("src/assets/signup.jpg");

export function NewOnboarding() {
  const [search] = useSearchParams();
  const toast = useMyToast();
  const navigate = useNavigate();

  // get the most recent path element
  const path = window.location.pathname.split("/").pop();

  const [hasCalled, setHasCalled] = React.useState(false);
  const [isForMe, setIsForMe] = useLocalStorage("is_for_me", false);
  const [phoneNumber, setPhoneNumber] = useLocalStorage("phone_number", "");
  const [hasClickedAdd, setHasClickedAdd] = React.useState(false);

  // the flow is onboarding screen => who are you (ex. is it for you or family member)
  // then it goes to add to contacts => test call => add to contacts again (just to make sure they actually added)
  // and then goes to actual signup page to signup for Quickfix
  const [step, setStep] = React.useState<
    | "onboarding"
    | "test-call"
    | "who-are-you"
    | "add-to-contacts"
    | "add-to-contacts-retry"
  >(path as any);

  const dispatch = useDispatch();

  useEffect(() => setStep(path as any), [path]);

  const [testCall] = useMutation<
    Pick<Mutation, "testCall">,
    MutationTestCallArgs
  >(api.families.testCall);

  const [updateActiveMember] = useMutation<
    Pick<Mutation, "updateActiveFamilyMember">,
    MutationUpdateActiveFamilyMemberArgs
  >(api.families.updateActiveMember);

  const [getActiveFamilyMember] = useLazyQuery<
    Pick<Query, "getActiveFamilyMember">
  >(api.families.getActiveFamilyMember);

  const _getPhoneNumber = (): string | null => {
    // parse the phone number to E.164
    const rawPhone = phoneNumber || "";

    // if it starts with a +, then it's already in E.164 format
    if (rawPhone.startsWith("+")) {
      return rawPhone;
    }

    // otherwise parse it into E.164 using the US country code
    const parsedPhoneNumber = parsePhoneNumberFromString(rawPhone, "US");

    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      return parsedPhoneNumber.format("E.164");
    }

    // return null if parsing fails or the number is invalid
    return null;
  };

  const _onContinue = async () => {
    // parse the phon number to E.164
    const phoneNumber = _getPhoneNumber();

    if (!phoneNumber) {
      toast.show({
        message: "Invalid phone number",
        status: "error",
      });
      return;
    }

    try {
      const variables: MutationUpdateActiveFamilyMemberArgs = {
        phoneNumber: phoneNumber,
      };

      const activeMemberResponse = await updateActiveMember({
        variables,
      });

      const familyMemberId =
        activeMemberResponse?.data?.updateActiveFamilyMember?.id;

      if (!familyMemberId) {
        toast.show({
          message: "An error occurred. Please try again.",
          status: "error",
        });
        return;
      }

      await testCall({
        variables: {
          familyMemberId: familyMemberId,
        },
      });

      toast.show({
        message: "You will receive a call shortly.",
        status: "success",
      });

      setHasCalled(true);
    } catch (err) {
      toast.show({
        message: "An error occurred. Please try again.",
        status: "error",
      });
    }
  };

  const _onNextStep = async () => {
    if (step === "onboarding") {
      navigate("/onboarding/who-are-you");
    } else if (step === "who-are-you") {
      setIsForMe(isForMe);

      navigate("/onboarding/add-to-contacts");
    } else if (step === "add-to-contacts") {
      setIsForMe(isForMe);
      setHasClickedAdd(false);
      navigate("/onboarding/test-call");
    } else if (step === "test-call") {
      setHasClickedAdd(false);
      navigate("/onboarding/add-to-contacts-retry");
    } else if (step === "add-to-contacts-retry") {
      // add is for me and phone number to query params
      navigate(
        "/signup?isElder=" + isForMe + "&phoneNumber=" + _getPhoneNumber()
      );
    }
  };

  const _onAddRuthToContacts = async () => {
    setHasClickedAdd(true);

    const familyMemberResponse = await getActiveFamilyMember();
    const familyMember = familyMemberResponse?.data?.getActiveFamilyMember;

    dispatch(
      show("ShareRuthContactModal", {
        familyMember: familyMember,
      })
    );
  };

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <div
      style={{
        backgroundColor: colors.primary,
      }}
    >
      <ShareRuthContactModal />

      <HStack
        padding="0"
        minH="100vh"
        display="flex"
        w="100%"
        h="100vh"
        gap={0}
      >
        <Box
          display="flex"
          flexDir="column"
          h="100%"
          overflowY="scroll"
          w="100%"
          maxW={!isLarge ? "100%" : "500px"}
          marginRight={0}
          flex={1}
          padding="2rem"
          bg={theme.background}
        >
          <Heading
            textAlign="left"
            color={theme.header}
            className="spectral-bold"
          >
            Get started with Quickfix
          </Heading>

          {step === "onboarding" && (
            <>
              <div
                style={{
                  marginTop: 15,
                  padding: "15px 15px 5px 15px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: 15,
                  backgroundColor: theme.secondaryBackground,
                }}
              >
                <HStack spacing={1} align="center">
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                </HStack>
                <Text
                  style={{
                    position: "relative",
                    top: -5,
                    marginTop: 0,
                    fontFamily: "Spectral",
                  }}
                >
                  <i
                    className="fa fa-quote-left"
                    style={{ color: colors.primary }}
                  />
                  <br />I was wondering why my mom was so happy today? Turns out
                  she called Quickfix.
                  <div style={{ textAlign: "right" }}>
                    <i
                      className="fa fa-quote-right"
                      style={{ color: colors.primary, alignSelf: "flex-end" }}
                    />
                  </div>
                </Text>
              </div>

              <br />

              <Divider />

              <Text marginTop="1rem" color={theme.text} fontSize="large">
                Chat with Quickfix anytime you want. Quickfix is there to help
                you or loved ones.
              </Text>

              <List style={{ padding: "2rem 0" }} marginBottom="0 !important">
                <ListItem
                  iconName="fas fa-heart"
                  header="Friend"
                  subtitle="Chat for as long as you want about anything you want."
                />

                <ListItem
                  iconName="fas fa-calendar-alt"
                  header="Daily check-in"
                  subtitle="Check in with loved ones daily."
                />

                <ListItem
                  iconName="fas fa-pills"
                  header="Medications"
                  subtitle="Get reminders about medications."
                />
              </List>

              {/* <Text textAlign="center" fontSize="xs" color={theme.text}>
              By signing up, you opt-in to receiving calls / sms messages from
              Quickfix.
            </Text> */}

              <br />
            </>
          )}

          {step === "who-are-you" && (
            <>
              <br />

              <Text
                className="spectral-bold"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                }}
                marginBottom="1rem"
              >
                Who will be using Quickfix?
              </Text>

              {/* two options, can click a circle and color it in. me or "for someone else" */}

              <div>
                <Option
                  label="I will"
                  isActive={isForMe}
                  onSelect={() => setIsForMe(true)}
                />
                <Option
                  label="Someone else will"
                  subtitle="Ex. your parent or grandparent"
                  isActive={!isForMe}
                  onSelect={() => setIsForMe(false)}
                />
              </div>

              <br />
            </>
          )}

          {(step === "add-to-contacts" || step === "add-to-contacts-retry") && (
            <>
              <br />

              <Text
                className="spectral-bold"
                style={{
                  color: theme.header,
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                {step === "add-to-contacts-retry"
                  ? "Just checking, did you make sure to add Quickfix to your contacts?"
                  : "Add Quickfix to your contacts"}
              </Text>

              <Text marginTop={15} fontSize="lg" color={theme.text}>
                Add Quickfix to your contacts to get the best experience, and to
                make sure that no calls are missed. You need to add Quickfix to
                your contacts to call her.
              </Text>

              <br />

              <Button
                variant={
                  step === "add-to-contacts-retry"
                    ? "secondary"
                    : hasClickedAdd
                    ? "secondary"
                    : "primary"
                }
                onClick={_onAddRuthToContacts}
              >
                Add Quickfix to contacts{" "}
                <i style={{ marginLeft: 5 }} className="fa fa-address-book" />
              </Button>

              <br />

              {/* show it if they clicked it the first time or we are on the retry screen */}
              {(hasClickedAdd || step === "add-to-contacts-retry") && (
                <Button
                  width="100%"
                  variant="primary"
                  type="submit"
                  onClick={_onNextStep}
                >
                  Continue
                </Button>
              )}
            </>
          )}

          {step === "test-call" && (
            <>
              <br />

              <Text
                className="spectral-bold"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                }}
                marginBottom="0.25rem"
              >
                Try out Quickfix
              </Text>

              <Box width="100%">
                <Text
                  style={{
                    fontSize: 16,
                    color: theme.text,
                    fontWeight: "normal",
                  }}
                  marginBottom="2rem"
                >
                  Chat with Quickfix for a few minutes to test her out. You can
                  hangup at any time.
                </Text>
              </Box>

              {/* two options, can click a circle and color it in. me or "for someone else" */}

              <div>
                <Input
                  isRequired
                  label={"Phone number"}
                  subtitle="🛡️ Your security is our priority. We will never share this number."
                  placeholder="Phone Number"
                  value={new AsYouType("US").input(phoneNumber || "")}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <br />

              <Button
                onClick={_onContinue}
                padding="1rem 0.5rem"
                variant={hasCalled ? "secondary" : "primary"}
                style={{ width: "100%" }}
              >
                Call me{" "}
                <i
                  style={{ marginLeft: 15 }}
                  className="fa-sharp fa-phone-alt"
                />
              </Button>

              <Text
                color={theme.text}
                style={{ marginTop: 15 }}
                fontSize="md"
                textAlign="center"
                textDecor="underline"
                cursor="pointer"
                onClick={_onNextStep}
              >
                Or skip for now
              </Text>

              <br />
            </>
          )}

          {step !== "add-to-contacts" &&
            step !== "test-call" &&
            step !== "add-to-contacts-retry" && (
              <Button
                width="100%"
                variant="primary"
                type="submit"
                onClick={_onNextStep}
              >
                {step === "onboarding" ? "Get started" : "Continue"}
              </Button>
            )}

          <Divider margin="2rem auto" />

          <Text textAlign="left" color={theme.text}>
            Already have an account?&nbsp;
            <Box display="inline-block" textDecor="underline" color="black">
              <Link to="/login">
                <Text color={colors.primary}>Log in</Text>
              </Link>
            </Box>
          </Text>

          <br />
          <br />
          <br />
        </Box>

        {isLarge && (
          <div
            style={{
              flex: 1,
              height: "100%",
              minHeight: "100vh",
              backgroundColor: colors.primary,
              // background of SignupImage, contain
              background: `url(${SignupImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        )}
      </HStack>
    </div>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 45,
          height: 45,
          backgroundColor: colors.primary,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 18,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.gray30,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} style={{ color: colors.white }} />
      </div>
      <VStack
        alignItems="flex-start"
        style={{
          gap: 0,
        }}
      >
        <Text
          className="spectral-extrabold "
          style={{ fontSize: 18, fontWeight: "bold" }}
          color={theme.header}
          marginBottom="0 !important"
        >
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};

const Option = ({
  onSelect,
  label,
  subtitle,
  isActive,
}: {
  onSelect: () => void;
  label: string;
  subtitle?: string;
  isActive: boolean;
}) => {
  const theme = useTheme();

  return (
    <HStack style={{ marginBottom: 15, cursor: "pointer" }} onClick={onSelect}>
      <div
        style={{
          backgroundColor: isActive ? colors.primary : theme.ternaryBackground,
          width: 30,
          height: 30,
          borderRadius: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isActive ? (
          <i className="fa-sharp fa-check" style={{ color: colors.white }} />
        ) : null}
      </div>

      <div
        style={{
          flex: 1,
        }}
      >
        <Text className="spectral-semibold" fontSize="lg" fontWeight="600">
          {label}
        </Text>

        <Text className="spectral-semibold" fontSize="sm" fontWeight="400">
          {subtitle}
        </Text>
      </div>
    </HStack>
  );
};
