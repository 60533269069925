import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DefaultOptions,
  ApolloLink,
} from "@apollo/client";
import { config } from "src/config";
import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "src/utils/firebase";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";

const uri = `${config.apiUrl}/graphql`;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};

const cache = new InMemoryCache({
  // resultCacheMaxSize: Math.pow(2, 6), // max things allowed in cache, default is 2^16
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink: any = createUploadLink({
  uri,
});

export const apolloClient = new ApolloClient({
  // does auth link and then after does http request
  link: retryLink.concat(authLink).concat(uploadLink),
  cache,
  defaultOptions,
});
