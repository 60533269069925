import { combineReducers } from "@reduxjs/toolkit";
import { reducer as modalReducer } from "redux-modal";

import { userReducer } from "./user";
import { globalStateReducer } from "./globalState";

export const reducers = combineReducers({
  user: userReducer,
  global: globalStateReducer,
  modal: modalReducer,
});
