import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors, other } from "src/theme";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationCreateUserArgs,
  MutationStartCallArgs,
  PhoneNumber,
  Query,
  QueryGetConversationArgs,
  QueryGetDailyPromptArgs,
  QueryGetDailyPromptsArgs,
  QueryGetFamilyMemberArgs,
  QueryGetFamilyMembersArgs,
  QueryGetFamilyPhonesArgs,
  TranscriptMessage,
} from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import { BaseDailyPromptFields, BaseUserFields } from "src/api/fragments";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import AudioPlayer from "./AudioPlayer";
import "react-h5-audio-player/lib/styles.css";
import { DailyPromptModal } from "src/components/modals";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { Touchable } from "src/components/Touchable";
import moment from "moment";

export function Conversation() {
  const [search] = useSearchParams();
  const toast = useMyToast();
  const navigate = useNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();
  const dispatch = useDispatch();

  const theme = useTheme();

  const convoVariables = useMemo(
    (): QueryGetConversationArgs => ({
      conversationId: conversationId || "",
    }),
    []
  );

  const { data: convoData } = useQuery<Pick<Query, "getConversation">>(
    api.conversations.retrieve,
    {
      variables: convoVariables,
    }
  );

  const conversation = convoData?.getConversation ?? null;

  const familyMemberVariables = useMemo(
    (): QueryGetFamilyMemberArgs => ({
      familyMemberId: conversation?.familyMemberId || "",
    }),
    [conversation]
  );

  const { data: familyMemberData } = useQuery<Pick<Query, "getFamilyMember">>(
    api.families.getMember,
    {
      variables: familyMemberVariables,
    }
  );

  const familyMember = familyMemberData?.getFamilyMember;
  const conversationAt = conversation?.createdAt ?? null;

  const transcripts = useMemo((): (TranscriptMessage & { id: string })[] => {
    if (!conversation) return [];

    return [conversation]
      .flatMap((c) => c.transcript)
      .map((t, i) => ({ ...t, id: `${i}` }));
  }, [conversation]);

  return (
    <div style={{}}>
      <VStack
        padding="2rem 0"
        flexDir="column"
        display="flex"
        justifyContent="center"
      >
        <div
          style={{
            maxWidth: "50rem",
            width: "100%",
          }}
        >
          <Touchable
            style={{
              alignSelf: "flex-start",
              marginBottom: 20,
              display: "inline-block",
            }}
            onClick={() => navigate(-1)}
          >
            <i
              className="fas fa-chevron-left"
              style={{
                color: theme.text,
                fontSize: 16,
              }}
            />
            Back
          </Touchable>

          <Box
            display="flex"
            flexDir="column"
            margin="auto"
            padding="2rem"
            w="100%"
            borderRadius={other.borderRadius}
            bg={theme.background}
            border={`1px solid ${theme.border}`}
          >
            <HStack>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,
                    color: theme.header,
                    fontWeight: "bold",
                  }}
                >
                  Conversation with {familyMember?.name}
                </Text>

                {conversationAt ? (
                  <HStack
                    style={{
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: theme.text,
                      }}
                    >
                      Call @{" "}
                      <b>{moment(conversationAt).format("MMM Do, h:mma")}</b>
                    </Text>
                  </HStack>
                ) : null}
              </div>
            </HStack>

            <AudioPlayer url={conversation?.recordingUrl || ""} />

            <div
              style={{
                marginTop: 30,
              }}
            >
              {transcripts.map((t, i) => (
                <div
                  style={{
                    marginBottom: 25,
                  }}
                  key={t.id}
                >
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    <b>
                      {t.role === "agent" ? "Quickfix" : familyMember?.name}
                    </b>{" "}
                    <StartTime seconds={t.words[0]?.start ?? null} />
                  </Text>
                  <Text
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {t.content}
                  </Text>
                </div>
              ))}
            </div>
          </Box>
        </div>
      </VStack>
    </div>
  );
}

const StartTime = ({ seconds }: { seconds?: number | null }) => {
  const theme = useTheme();

  if (seconds === undefined || seconds === null) {
    return null;
  }
  // render as 0:12, 1:13, etc... minutes + seconds
  const minutes = Math.floor(seconds / 60);
  // pad this
  const secondsLeft = Math.floor(seconds - 60 * minutes);
  const paddedSeconds = secondsLeft.toString().padStart(2, "0");

  return (
    <div
      style={{
        fontSize: 16,
        display: "inline-block",
        color: theme.text,
        fontWeight: "normal",
      }}
    >
      ({minutes}:{paddedSeconds})
    </div>
  );
};
