import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors, other } from "src/theme";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationCreateUserArgs,
  MutationStartCallArgs,
  PhoneNumber,
  Query,
  QueryGetActiveFamilyArgs,
  QueryGetFamilyPhonesArgs,
} from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import {
  BaseFamilyFields,
  BaseFamilyMemberFields,
  BaseUserFields,
} from "src/api/fragments";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import StatusTag from "src/components/StatusTag";
import { useActiveFamily } from "src/hooks/useActiveFamily";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

export function Dashboard() {
  const theme = useTheme();
  const { familySlug } = useParams();

  const navigate = useNavigate();

  const [getActiveFamily] = useLazyQuery<Pick<Query, "getActiveFamily">>(
    api.families.getActiveFamily,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const _setFamilySlug = async () => {
    const response = await getActiveFamily();

    if (response.data?.getActiveFamily) {
      navigate(`/app/${response?.data?.getActiveFamily?.slug}`);
    }
  };

  useEffect(() => {
    if (!familySlug) {
      _setFamilySlug();
    }
  }, []);

  const isLarge = useIsLargeScreen();

  return (
    <div style={{}}>
      <VStack padding="2rem 0">
        <Box
          display="flex"
          flexDir="column"
          maxWidth="50rem"
          margin="auto"
          padding={isLarge ? "2rem" : "1rem"}
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <HStack style={{ marginBottom: 25 }}>
            <Text
              style={{
                flex: 1,
                fontSize: 22,
                fontWeight: "bold",
                fontFamily: "Spectral",
              }}
            >
              Family
            </Text>

            <Link to={`/app/${familySlug}/members/new`}>
              <Button variant="primary">Add Member</Button>
            </Link>
          </HStack>

          <Members />
        </Box>
      </VStack>
    </div>
  );
}

const Members = () => {
  const { familyMembers } = useActiveFamily();

  const { familySlug } = useParams();
  const { me } = useMe();
  const members = useMemo(() => {
    const m = familyMembers as BaseFamilyMemberFields[];

    return m; // m.filter((m) => m.userId !== me?.id);
  }, [familyMembers]);

  const theme = useTheme();

  if (!members?.length) {
    // empty state
    return (
      <div
        style={{
          padding: "25px 10px",
          textAlign: "center",
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: 24,
            marginBottom: 50,
            color: theme.header,
          }}
        >
          Invite your first family member to Quickfix!
        </Text>

        <Button variant="primary" style={{}}>
          Add Family Member
        </Button>
      </div>
    );
  }

  return (
    <div>
      {members.map((member: BaseFamilyMemberFields) => (
        <Link to={`/app/${familySlug}/members/${member.id}`} key={member.id}>
          <div
            style={{
              padding: 15,
              margin: "10px 0",
              borderRadius: 10,
              border: `1px solid ${theme.border}`,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MemberProfileIcon member={member} />
            <div style={{ flex: 1 }}>
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: theme.header,
                }}
              >
                {member.name}{" "}
                {member.userId === me?.id ? (
                  <div style={{ marginLeft: 10, display: "inline-block" }}>
                    <StatusTag
                      // iconName="fas fa-user-circle"
                      label="You"
                      type="info"
                    />
                  </div>
                ) : null}
              </Text>
              <Text
                style={{
                  marginRight: 15,
                  color: theme.text,
                }}
              >
                {member?.formattedPhone}
              </Text>
            </div>

            <i
              className="fa fa-chevron-right"
              style={{ fontSize: 20, color: theme.text }}
            ></i>
          </div>
        </Link>
      ))}
    </div>
  );
};

const MemberProfileIcon = ({ member }: { member: BaseFamilyMemberFields }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        marginRight: 15,
        backgroundColor: colors.primary,
        width: 40,
        height: 40,
        borderRadius: 5,
        color: colors.white,
        fontWeight: "bold",
        fontSize: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {member?.name?.charAt(0).toUpperCase()}
    </Box>
  );
};
