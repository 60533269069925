import React, { useContext } from "react";
import { FamilyMemberContext } from "./context";
import { ConversationRow } from "./ConversationRow";

function Conversations() {
  const { conversations } = useContext(FamilyMemberContext);

  return (
    <div>
      <div>
        {!conversations.length && (
          <div>
            No conversations have been recorded yet. When they do, they'll be
            visible here.
          </div>
        )}
        {conversations.map((row) => (
          <ConversationRow conversation={row} />
        ))}
      </div>
    </div>
  );
}

export default Conversations;
