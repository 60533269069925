import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import {
  Conversation,
  DailyPrompt,
  Family,
  FamilyMember,
  PhoneNumber,
  TranscriptMessage,
  User,
} from "./generated/types";

export type BaseUserFields = Pick<
  User,
  | "__typename"
  | "id"
  | "email"
  | "name"
  | "activeFamilyId"
  | "createdAt"
  | "isSuperuser"
>;

export const BaseUserFields = gql`
  fragment BaseUserFields on User {
    id
    email
    name
    activeFamilyId
    createdAt
    isSuperuser
  }
`;

export type BaseFamilyFields = Pick<
  Family,
  "__typename" | "id" | "name" | "createdAt" | "slug"
>;

export const BaseFamilyFields = gql`
  fragment BaseFamilyFields on Family {
    id
    name
    slug
    createdAt
  }
`;

export type BasePhoneNumberFields = Pick<
  PhoneNumber,
  "__typename" | "id" | "name" | "phoneNumber"
>;

export const BasePhoneNumberFields = gql`
  fragment BasePhoneNumberFields on PhoneNumber {
    id
    name
    phoneNumber
  }
`;

export type BaseConversationFields = Pick<
  Conversation,
  | "__typename"
  | "id"
  | "createdAt"
  | "transcriptionUrl"
  | "durationMs"
  | "recordingUrl"
  | "callSummary"
  | "status"
  | "familyMemberId"
  | "durationFormatted"
> & {
  transcript?: TranscriptMessage[];
};

export const BaseConversationFields = gql`
  fragment BaseConversationFields on Conversation {
    id
    createdAt
    transcriptionUrl
    durationMs
    recordingUrl
    callSummary
    status
    familyMemberId
    durationFormatted
    transcript {
      role
      content
      words {
        word
        start
        end
      }
    }
  }
`;

export type BaseFamilyMemberFields = Pick<
  FamilyMember,
  | "__typename"
  | "id"
  | "familyId"
  | "isElder"
  | "name"
  | "description"
  | "nextCallAt"
  | "phoneNumberId"
  | "userId"
  | "phoneNumber"
  | "phone"
  | "formattedPhone"
  | "formattedFromPhoneNumber"
  | "timezone"
  | "timesOfDay"
  | "daysOfWeek"
  | "lastCallAt"
  | "nextCallAt"
  | "shouldAutomaticallyCall"
  | "interestDescription"
>;

export const BaseFamilyMemberFields = gql`
  ${BasePhoneNumberFields}
  fragment BaseFamilyMemberFields on FamilyMember {
    id
    familyId
    isElder
    name
    description
    phoneNumberId
    userId
    phoneNumber {
      ...BasePhoneNumberFields
    }
    phone
    formattedPhone
    formattedFromPhoneNumber
    timezone
    timesOfDay
    daysOfWeek
    lastCallAt
    nextCallAt
    shouldAutomaticallyCall
    interestDescription
  }
`;

export type BaseDailyPromptFields = Pick<
  DailyPrompt,
  | "__typename"
  | "id"
  | "isCompleted"
  | "prompt"
  | "position"
  | "createdAt"
  | "familyMemberId"
> & {
  conversations?: BaseConversationFields[] | null;
};

export const BaseDailyPromptFields = gql`
  ${BaseConversationFields}
  fragment BaseDailyPromptFields on DailyPrompt {
    id
    isCompleted
    prompt
    position
    createdAt
    familyMemberId
    conversations {
      ...BaseConversationFields
    }
  }
`;
