import React, { useCallback, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  List,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors, other } from "src/theme";
import Confetti from "react-confetti";
import { motion } from "framer-motion";
import { useMe, useMyToast } from "src/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isEmpty, isNil } from "lodash/fp";
import { Button } from "src/components/Button";
import { useTheme } from "src/hooks/useTheme";
import { useMutation } from "@apollo/client";
import { api } from "src/api";

export function WhoYouAre() {
  const toast = useMyToast();
  const [runConfetti] = useState(false);
  const navigate = useNavigate();
  const { familySlug } = useParams();
  const theme = useTheme();
  const [isForMe, setIsForMe] = useState(false);

  const [updateActiveMember] = useMutation(api.families.updateActiveMember);

  const _onContinue = async () => {
    await updateActiveMember({
      variables: {
        isElder: isForMe,
      },
    });

    navigate(`/app/${familySlug}/welcome?isElder=${isForMe}`);
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <VStack padding="2rem 0" flexDir="column" display="flex">
        <Confetti
          run={runConfetti}
          colors={[
            // colors.blue50,
            // colors.green50,
            // colors.purple50,
            // colors.red50,
            colors.yellow50,
            colors.orange50,
            // colors.pink50,
          ]}
          numberOfPieces={250}
          recycle={false}
        />

        <Box
          display="flex"
          flexDir="column"
          maxWidth="30rem"
          margin="auto"
          padding="2rem"
          h="100%"
          w="100%"
          borderRadius={other.borderRadius}
          bg={colors.white}
          border={other.boxBorder}
        >
          <Box width="100%">
            <Text
              className="spectral-bold"
              style={{
                fontSize: 24,
                fontWeight: "bold",
              }}
              marginBottom="1rem"
            >
              Who will be using Quickfix?
            </Text>

            <br />

            {/* two options, can click a circle and color it in. me or "for someone else" */}

            <div>
              <Option
                label="I will"
                isActive={isForMe}
                onSelect={() => setIsForMe(true)}
              />
              <Option
                label="Someone else will"
                subtitle="Ex. your parent or grandparent"
                isActive={!isForMe}
                onSelect={() => setIsForMe(false)}
              />
            </div>

            <motion.div
              style={{ marginTop: 50 }}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
            >
              <Button
                onClick={_onContinue}
                padding="1.5rem 0.5rem"
                disabled={runConfetti}
                bg={`linear-gradient(30deg, ${colors.primary} 0%, ${colors.green10} 50%, ${colors.primary} 100%) !important`}
                color={colors.white}
                style={{ width: "100%" }}
              >
                Continue{" "}
                <i
                  style={{ marginLeft: 15 }}
                  className="fa-sharp fa-arrow-right"
                />
              </Button>
            </motion.div>
          </Box>
        </Box>
      </VStack>
    </div>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 35,
          height: 35,
          backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 18,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.gray30,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text fontSize="md" fontWeight="600">
          {header}
        </Text>
        <Text marginTop="0 !important" fontSize="sm">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};

const Option = ({
  onSelect,
  label,
  subtitle,
  isActive,
}: {
  onSelect: () => void;
  label: string;
  subtitle?: string;
  isActive: boolean;
}) => {
  const theme = useTheme();

  return (
    <HStack style={{ marginBottom: 15, cursor: "pointer" }} onClick={onSelect}>
      <div
        style={{
          backgroundColor: isActive ? colors.primary : theme.ternaryBackground,
          width: 30,
          height: 30,
          borderRadius: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isActive ? (
          <i className="fa-sharp fa-check" style={{ color: colors.white }} />
        ) : null}
      </div>

      <div
        style={{
          flex: 1,
        }}
      >
        <Text className="spectral-semibold" fontSize="lg" fontWeight="600">
          {label}
        </Text>

        <Text className="spectral-semibold" fontSize="sm" fontWeight="400">
          {subtitle}
        </Text>
      </div>
    </HStack>
  );
};
