import {
  Button,
  Container,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { Modal } from "../Modal";
import {
  BaseDailyPromptFields,
  BaseFamilyMemberFields,
} from "src/api/fragments";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddDailyPromptArgs,
  MutationUpdateDailyPromptArgs,
  MutationUpdateFamilyMemberArgs,
  Query,
} from "src/api/generated/types";
import { Input, Textarea } from "../Form";
import { useMyToast } from "src/hooks";

type Props = InjectedProps & {
  familyMember?: BaseFamilyMemberFields;
};

function _FamilyMemberDescriptionModal({
  handleHide,
  show: isVisible,
  familyMember,
}: Props) {
  const theme = useTheme();
  const toast = useMyToast();

  const [description, setDescription] = useState<string | null>(
    familyMember?.description || ""
  );

  const [interests, setInterests] = useState<string | null>(
    familyMember?.interestDescription || ""
  );

  const [updateFamilyMember] = useMutation<
    Pick<Mutation, "updateFamilyMember">,
    MutationUpdateFamilyMemberArgs
  >(api.families.updateMember);

  const _updateFamilyMember = async () => {
    if (!familyMember) {
      return toast.show({
        message: "No family member found.",
        status: "error",
      });
    }

    if (!description) {
      return toast.show({
        message: "Please update the description.",
        status: "error",
      });
    }

    const variables: MutationUpdateFamilyMemberArgs = {
      familyMemberId: familyMember.id,
      description: description,
      name: familyMember.name || "",
      interestDescription: interests,
    };

    const response = await updateFamilyMember({
      variables,
      refetchQueries: [api.families.getMember, api.families.members],
    });

    toast.show({
      message: "Updated family member.",
      status: "success",
    });

    handleHide();
  };

  const _onSave = async () => {
    try {
      if (familyMember) {
        await _updateFamilyMember();
      }
    } catch (err) {
      toast.show({
        message: "An error occurred while updating family member.",
        status: "error",
      });
    }

    // TODO:
  };

  return (
    <Modal
      title={`${familyMember?.name || "Family Member"}`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={_onSave}
        >
          Save
        </Button>
      }
    >
      <Textarea
        label="Biography"
        autoFocus={true}
        value={description || ""}
        onChange={(e) => setDescription(e.target.value)}
      />

      <br />

      <Textarea
        label="Interests"
        value={interests || ""}
        onChange={(e) => setInterests(e.target.value)}
      />

      <br />
    </Modal>
  );
}

export const FamilyMemberDescriptionModal = connectModal({
  name: "FamilyMemberDescriptionModal",
})(_FamilyMemberDescriptionModal);
