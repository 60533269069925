import React, { CSSProperties, useState } from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

type MyButtonProps = ChakraButtonProps;

export const Button = (props: MyButtonProps) => {
  const [isLoading, setLoading] = useState(false);

  const _onClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    setLoading(true);
    try {
      if (props.onClick) {
        await props.onClick(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChakraButton
      {...props}
      isLoading={props.isLoading || isLoading}
      onClick={_onClick}
    >
      {props.children}
    </ChakraButton>
  );
};
