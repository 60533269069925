import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Text,
} from "@chakra-ui/react";
import { CSSProperties, useCallback, useMemo } from "react";
import { colors } from "src/theme";
import { Controller, Control } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "../Info";
import ReactSelect, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
} from "react-select";
import makeAnimated from "react-select/animated";
import { isString } from "lodash/fp";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import { Required } from "./Required";
import { useTheme } from "src/hooks/useTheme";

const animatedComponents = makeAnimated();

type MyInputProps = {
  label?: string;
  labelIconName?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  isRequired?: boolean;
  value?: PropsValue<boolean>;
  placeholder?: string;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const Checkbox = (props: MyInputProps) => {
  const theme = useTheme();

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  return (
    <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
      <FormControl isInvalid={!!props.error}>
        {props.label && (
          <FormLabel fontSize="sm" color={theme.header}>
            {props.labelIconName && (
              <i style={{ marginRight: 5 }} className={props.labelIconName} />
            )}
            {props.label} {props.isRequired && <Required />}
            {props.infoMessage && (
              <Info
                style={{
                  color: theme.text,
                }}
                message={props.infoMessage}
              />
            )}
          </FormLabel>
        )}

        {props.control && props.name ? (
          <Controller
            control={props.control}
            name={props.name}
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <HStack>
                <input
                  style={{ background: theme.background }}
                  type="checkbox"
                  onChange={onChange}
                  checked={value ? true : false}
                  ref={ref}
                />
                <Text color={theme.text} fontSize="sm" fontWeight="normal">
                  {props.placeholder}
                </Text>
              </HStack>
            )}
          />
        ) : (
          <HStack>
            <input
              type="checkbox"
              onChange={props.onChange}
              checked={props.value ? true : false}
            />
            <Text fontSize="sm" color={theme.text} fontWeight="normal">
              {props.placeholder}
            </Text>
          </HStack>
        )}

        <FormErrorMessage>{props.error}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
